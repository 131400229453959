import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

interface Props{
    titleId: string; // key of the item that should appear as title
    callBackId: string; // id name of list item that will be passed in the function callBack function when title is clicked
    callBack : (id:string) => void; // function that is called when the title is clicked
    item : any; // list that needs to display
    itemOptions? : any[]; // three dot option. Ex: [{'name':'View event', 'callBack': profileModal, 'callBackId': 'user'}]
    subtitleId?: string; // key of the item that should appear as subtitle
    subtitleName?:string; // name shown for subtitle
    status?: string; // if it needs to be filtered by status
}

function classNames(...classes:any[]) {
    return classes.filter(Boolean).join(' ')
}

const ListWithIconsElement: React.FC<Props> = ({item, callBack, callBackId, titleId, itemOptions, subtitleId, subtitleName}) => {
  return (
    <div>
        <li className="flex justify-between gap-x-6 py-5 ">
            <div className="flex min-w-0 gap-x-4">
                {item.icon? <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={item.icon} alt="Icon" />:null }
                
                <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                        <a onClick={(e) =>{callBack(item[callBackId]); e.preventDefault();}} href='#' className="hover:underline">
                            {item[titleId]}
                        </a>
                    </p>
                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                        { subtitleName ? subtitleName + ' :': ''}  { subtitleId? item[subtitleId]:''} 
                    </p>
                </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-6">
                {/* <div className="hidden sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">{person.role}</p>
                    {person.lastSeen ? (
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                        Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
                        </p>
                    ) : (
                        <div className="mt-1 flex items-center gap-x-1.5">
                        <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                        </div>
                        <p className="text-xs leading-5 text-gray-500">Online</p>
                        </div>
                    )}
                </div> */}

                {itemOptions? 
                    <Menu as="div" className="relative flex-none">
                        
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            
                                {Object.keys(itemOptions).map((key:any, index) => ( 
                                    <Menu.Item key={index}>
                                        {({ active }) => (
                                            <a
                                                onClick={(e) =>{itemOptions[key].callBack(item[itemOptions[key].callBackId]); e.preventDefault();}}
                                                href="#"
                                                className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                            >
                                                {itemOptions[key].name}<span className="sr-only">, {item.user}</span>
                                            </a>
                                        )}
                                    </Menu.Item> 
                                ))}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    : null
                }
            </div>
        </li>
    </div>
  )
}

export default ListWithIconsElement