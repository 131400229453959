import React, { useState } from 'react'
import { useEventApi } from "../../store";
import { PhotoIcon } from '@heroicons/react/24/solid'
import { AiOutlineClose } from 'react-icons/ai';
import ImagesSmallList from '../images/ImagesSmallList';
import { Button } from 'flowbite-react'
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';


interface Props{
    id: string;
    type?: string;
    apiFunction: (images: any) => Promise<any>;
    callBack: ()=> void;
    maxNumberOfFiles?: number
}

const ImagesUpload : React.FC<Props> = ({ id, type, apiFunction, callBack, maxNumberOfFiles }) => {
    // console.log('id: ', id)
    const { addEventPictures } = useEventApi();

    const [images, setImages] = useState<File[]>([])
    const [fileLimit, setFileLimit] = useState(false);
    const [currentSubView, setCurrentSubView] = useState<string>('edit');
    const [message, setMessage] = useState<string>('Sorry, there was an error');

    const onImageChange = (e: React.FormEvent<HTMLInputElement>) => {
        
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            var filesArr = Array.prototype.slice.call(e.currentTarget.files);

            const uploaded = [...images];
            console.log(uploaded)
            let limitExceeded = false;
            filesArr.some((file) => {
                // if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                    uploaded.push(file);
                    if (maxNumberOfFiles){
                        if (uploaded.length === (maxNumberOfFiles+1)) {setFileLimit(true);}
                        if (uploaded.length > maxNumberOfFiles) {
                            setMessage(`You can only add a maximum of ${maxNumberOfFiles} files...`)
                            setCurrentSubView('error')
                            setFileLimit(false)
                            limitExceeded = true;
                            return true
                        }
                    }
                    
                // }
            })
            if (!limitExceeded) {setImages(uploaded);}
        }
    }

    const removeImage = (index:string) => {
        const uploaded = [...images];
        const newImages = uploaded.filter((image, imageIndex) => imageIndex.toString() !== index.toString() )
        setImages(newImages);
        console.log(images)
    }


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader');

        const formData = new FormData();
        images.forEach(file=>{formData.append(file.name, file);});
        formData.append('id', id)
        if (type === 'message'){
            formData.append('inboxId', id)
            formData.append('type', 'picture')
            formData.append('message', e.currentTarget.chatInput.value? e.currentTarget.chatInput.value:'')
        }
        
        const apiCall = await apiFunction(formData)
        .then((result:any) => {
            if (result){setCurrentSubView('success')}else{setCurrentSubView('error')}
        });
    };


    return (
        <div>
            <label htmlFor="cover-photo" className="mt-6 flex items-center justify-center block text-sm font-medium leading-6 text-gray-900 ">
                Add pictures { (type !== 'message')? "(You can close and do it later)": ''}
            </label>
            {(currentSubView ==='loader')? <Loader />:null }

            {(currentSubView ==='edit')?
                <form onSubmit={handleSubmit}> 
                    <div className="mt-6 flex items-center justify-center ">
                        <label  className="flex flex-col items-center justify-center relative w-full md:w-1/2 h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, GIF up to 10MB</p>
                                {maxNumberOfFiles? <p className="text-xs text-gray-500 dark:text-gray-400">max: {maxNumberOfFiles} file(s)</p> :null}
                            </div>
                            <input id="dropzone-file" type="file" className="flex items-center justify-center absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer" multiple onInput={onImageChange} required />
                        </label> 
                    </div> 
                    <ImagesSmallList images={images} callBack={removeImage} />
                    { (type === 'message')? 
                        <textarea 
                            id="chatInput" 
                            rows={2} 
                            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                            placeholder="Add Caption..."
                        ></textarea>
                        :null
                    }
                    <div className="w-full flex justify-center mt-6">
                        <Button type='submit' disabled = {fileLimit}> { (type === 'message')? "Send": "Save"}</Button>
                    </div>
                    <div className="w-full flex justify-center mt-6">
                        <Button onClick={callBack} type='button' color="failure" >Cancel</Button>
                    </div>
                </form>:null 
            }

            {(currentSubView ==='success')? 
                <AlertComponent 
                callBack={callBack} 
                type='success' 
                subject='Success' 
                message={"Congratulations, you have successfully " + (type === 'message')? "sent this picture": "updated your profile picture ..."} />
                : null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent callBack={() => {setCurrentSubView('edit')}} 
                type='error' 
                subject='Failed' 
                message={message} />: null
            } 

        </div>
    )
}

export default ImagesUpload