import { useApi } from "./api"
// import { EventDTO, EventImage, EventPromoter, EventTicketPackageDTO } from "../models"
import { useContextState } from '../context/Context';


export const useMessagesApi = () => {
    const { $post, $get, $put, $patch, $delete } = useApi()

    const { accountCTX } = useContextState();
    const { signedIn } = accountCTX


    const getInboxes = async (credentials: {type:string}) => {
        if (!signedIn){return}
        var data = await $get('messages/inbox/', true, credentials)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }


    const getMessages = async (credentials: {id:string}) => {
        if (!signedIn){return}
        const path = "inbox/" + credentials.id + "/"
        var data = await $get(path, true)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const sendMessage = async (credentials: any) => {
        if (!signedIn){return}
        const path = "messages/"
        var data = await $post(path, credentials, true, false)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const sendSupportMessage = async (credentials: {subject:string, message:string, type:string}) => {
        if (!signedIn){return}
        const path = "inbox/"
        var data = await $post(path, credentials, true, false)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }



    return {
        getInboxes,
        getMessages,
        sendMessage,
        sendSupportMessage
    }

}