
import { useParams, useNavigate } from 'react-router-dom'
import { useContextState } from '../context/Context';
import LoginModal from '../components/modals/LoginModal';
import Messages from '../components/messages/Messages';


const MessagesPage = () => {
  const { accountCTX } = useContextState();
  const { signedIn } = accountCTX

  const navigate = useNavigate();

  const { inboxId } = useParams()

  return (
    <div >
      {!signedIn? 
          <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          :
          <div>
              <Messages inboxItem={inboxId} backButton={() => {return navigate(-1)} }/>
          </div>
      } 
    </div>
  )
}

export default MessagesPage