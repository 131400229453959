import { Textarea, Button } from 'flowbite-react'
import React, { useState } from 'react'
import { useMessagesApi } from '../../store';
import AlertComponent from '../shared/AlertComponent';
import Loader from '../shared/Loader';


interface Props{
    callBack: () => void;
}

const NewSupportMessage: React.FC<Props> = ({callBack}) => {

    const { 
        sendSupportMessage,
    } = useMessagesApi();

    const [currentSubView, setCurrentSubView] = useState<string>('edit');
    const [message, setMessage] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')

        var data = {
            subject: e.currentTarget.subject.value,
            message: e.currentTarget.message.value,
            type: 'support'
        }


        const apiCall = await sendSupportMessage(data)
        .then((result:any) => {
            if (result.res && result.res === 'success'){
                setMessage('You have successfully sent the support message. We will reply to you as soon as we can ...')
                setCurrentSubView('success')
            }
            else if(result.res && result.res === 'failed'){
                setMessage(result.message)
                setCurrentSubView('error')
            }
            else{
                setMessage('Sorry, there was an error ...')
                setCurrentSubView('error')
            }
        });
    };

    return (
        <div>
            {(currentSubView ==='loader')? <Loader />:null}

            {(currentSubView ==='edit')? 
                <form onSubmit={handleSubmit}>
                    <div className="mb-2 block mt-6">
                        <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900">
                            Subject
                        </label>
                        <div className="mt-2">
                        <input
                            type="text"
                            name="subject"
                            id="subject"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>

                    <div className="sm:col-span-3 mt-6">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                            Message
                        </label>
                        <div className="mt-2">
                            <Textarea
                                name="message"
                                id="message"
                                placeholder="Enter a description for your event ..."
                                rows={4}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="w-full flex justify-center mt-6">
                        <Button type='submit'>Send</Button>
                    </div>
                </form>:null
            }

            {(currentSubView ==='success')? 
                <AlertComponent
                    callBack={()=>{return callBack()}}
                    type='success' 
                    subject='Success' 
                    message={message} 
                />: null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent 
                    callBack={() => (setCurrentSubView('edit'))} 
                    type='error' 
                    subject='Failed' 
                    message={message}
                />: null
            }

        </div>
    )
}

export default NewSupportMessage