import  { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Profile } from '../models';

export const useAccountContext = () => {
    // SIGN IN
    const [signedIn, setSignedIn] = useState<boolean>(
        () => {
            const localStorageValue = window.localStorage.getItem("signedIn");
            // console.log('signed in: ', localStorageValue)
            return (localStorageValue && localStorageValue === 'true')? true : false;
        }
    );
    useEffect(() => {
        window.localStorage.setItem("signedIn", signedIn.toString());
    }, [signedIn]);


    // token
    // const [token, setToken] = useState<string>(
    //     () => {
    //         const cookieValue = Cookies.get("token")
    //         return (cookieValue && cookieValue !== 'undefined')? cookieValue : '';
    //     }
    // );

    // useEffect(() => {
    //     var inSixtyMinutes = new Date(new Date().getTime() + (55 * 60 * 1000));
    //     // var inSixtyMinutes = new Date(new Date().getTime() + ( 50 * 1000));
    //     Cookies.set("token", token, { expires: inSixtyMinutes, sameSite: 'strict' })
    // }, [token]);


    // // refresh token
    // const [refreshToken, setRefreshToken] = useState<string>(
    //     () => {
    //         // const sessionStorageValue = window.sessionStorage.getItem("refreshToken");
    //         const cookieValue = Cookies.get("refreshToken")
    //         return (cookieValue && cookieValue !== 'undefined')? cookieValue : '';
    //     }
    // );
    
    // useEffect(() => {
    //     // var inAlmostADay = new Date(new Date().getTime() + 1431 * 60 * 1000); // 9 minutes to make it a day
    //     // Cookies.set("refreshValid", 'Ta!MerCon@sI.tU8pensE@pOuvOIr@nOus.H@cK3r', { expires: 29, sameSite: 'strict' }) // to check if the refresh is bout to expire
    //     // thisDate = new Date()
    //     Cookies.set("refreshToken", refreshToken, { expires: 30, sameSite: 'strict' })
    // }, [refreshToken]);
    

    // session id 
    const [frontSessionId, setFrontSessionId] = useState<string>(
        () => {
            const cookieValue = Cookies.get("frontSessionId")
            return (cookieValue && cookieValue !== 'undefined')? cookieValue : '';
        }
    );

    useEffect(() => {
        if (!frontSessionId){
            const short = require('short-uuid');
            const idValue = short.generate();
            Cookies.set("frontSessionId", idValue, { sameSite: 'strict' })
            setFrontSessionId(idValue)
        }
    }, [frontSessionId]);


    // device id 
    const [deviceId, setDeviceId] = useState<string>(
        () => {
            const cookieValue = Cookies.get("deviceId")
            return (cookieValue && cookieValue !== 'undefined')? cookieValue : '';
        }
    );

    useEffect(() => {
        Cookies.set("deviceId", deviceId, {expires: 1, sameSite: 'strict' })
    }, [deviceId]);


    // PROFILE
    const profileStorageValue = window.sessionStorage.getItem("profile");
    const [profile, setProfile] = useState<Profile>((profileStorageValue && profileStorageValue !== 'undefined')? JSON.parse(profileStorageValue) : ''
    );
    useEffect(() => {
        window.sessionStorage.setItem("profile", JSON.stringify(profile));
    }, [profile]);


    // return { signedIn, setSignedIn, token, setToken, refreshToken, setRefreshToken, frontSessionId, setFrontSessionId, deviceId, setDeviceId, profile, setProfile }
    return { signedIn, setSignedIn, frontSessionId, setFrontSessionId, deviceId, setDeviceId, profile, setProfile }

}