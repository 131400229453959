import React from 'react'
import { useContextState } from '../../context/Context'
import '../styles.css'
import axios from 'axios';

interface Props{
    message: any,
}

const SingleMessage: React.FC<Props> = ({ message}) => {
    const { accountCTX } = useContextState();
    const { profile } = accountCTX

    const downloadFile = (filePath:string, contentType:string) => {
        fetch( filePath, {
          method: 'GET',
          mode: "cors",
          headers: {
            'Content-Type': contentType,
          },
        })
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
    
            const link = document.createElement('a');
            link.href = url;
            const short = require('short-uuid');
            const fileName = short.generate();
            link.download = fileName.toString();
    
            document.body.appendChild(link);
    
            link.click();

            if (link && link.parentNode){
                link.parentNode.removeChild(link);
            }
            
          });
    };

    // console.log(message.username, profile.username)
    

    return (
        <div>
            <div className= "flex items-start gap-2.5 " dir= {((message.support === true)? "rtl":'')}>
                <img className="w-8 h-8 rounded-full" src={message.icon} alt="Icon" />
                <div className={"flex flex-col w-64 max-w-[320px] leading-1.5 p-4 rounded-e-xl rounded-es-xl dark:bg-gray-700 " + ((message.support === true)? "border-blue-200 bg-blue-600":'border-gray-200 bg-gray-100')}  >
                    <div className={"flex items-center space-x-2 rtl:space-x-reverse " + ((message.support === true)? '':'')}>
                        <span className="text-sm font-semibold text-gray-900 dark:text-white rtl:text-white"> {message.username.charAt(0).toUpperCase() + message.username.slice(1)}</span>
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 rtl:text-gray-400">{new Date(message.updated_at).toTimeString().slice(0,9)}</span>
                    </div>
                    <div className='justify-items-end items-end' dir='ltr'>
                        <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white rtl:text-white " >{message.message}</p>
                    </div>
                    
                    {(message.type === 'picture')?
                        <div className="group relative my-2.5">
                            <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                                <a onClick={()=>{downloadFile(message.file, 'image/jpeg') }}  data-tooltip-target="download-image" className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50">
                                    <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"/>
                                    </svg>
                                </a>
                                <div id="download-image" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    Download image
                                    <div className="tooltip-arrow" data-popper-arrow></div>
                                </div>
                            </div>
                            <img src={message.file} className="rounded-lg" />
                        </div>
                        :null
                    }

                    { (message.support === true)?
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 rtl:text-gray-400">Delivered</span>:null
                    }
                    
                </div>
            </div>

        </div>
    )
}

export default SingleMessage