import React, { useState, useRef } from 'react'
import { useGlobalModalContext, MODAL_TYPES } from './GlobalModal';
import { useContextState } from '../../context/Context';
import { useEventApi } from "../../store";
import  { Label, Button, Modal, TextInput } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { EventTicketPackage } from '../../models';
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';




const EditEventPromoterModal = () => {
    const { hideModal, store } = useGlobalModalContext();
    const { modalProps } = store || {};
    const { title, confirmBtn, eventPromoter, eventId } = modalProps || {};

    const [currentSubView, setCurrentSubView] = useState<string>('edit');
    const { updateEventPromoter, addEventPromoter } = useEventApi();

    // const { accountCTX : {profile} } = useContextState();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')
            
        const data = {
            id : '', 
            eventId: eventId,
            name : e.currentTarget.promoterName.value, 
            pseudo : e.currentTarget.promoterPseudo.value.toLowerCase(),
        }
        
        var apiCall = ''
        if (!eventPromoter){apiCall = await addEventPromoter(data);}
        else{data['id'] = eventPromoter.id; apiCall = await updateEventPromoter(data);}
        
        if (apiCall){setCurrentSubView('success')}else{setCurrentSubView('error')}
        
    };


    return (
        <div>
            <Modal show={true} size="3xl" onClose={() => hideModal()}>
                <Modal.Header>Edit Promoter</Modal.Header>
                <Modal.Body>
                    {(currentSubView ==='loader')? <Loader />:null}
                    {(currentSubView === 'edit')?
                        <form onSubmit={handleSubmit}>
                            <div className="border-b border-gray-900/10 pb-6">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Event Promoter</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Kindly add a promoter for this event.</p>

                                <div className="mb-2 block mt-6">
                                    <Label htmlFor="promoterName" value="Kindly provide the promoter's name" />
                                </div>
                                <TextInput 
                                    type={ "text" } 
                                    id="promoterName" 
                                    placeholder="Provide the promoter's name ..."
                                    defaultValue={eventPromoter? eventPromoter.name:''}
                                    required 
                                />

                                <div className="mb-2 block mt-6">
                                    <Label htmlFor="promoterPseudo" value="Kindly provide a pseudo for this promoter (will be used for the promoter's link)" />
                                </div>
                                <TextInput
                                    name="promoterPseudo"
                                    id="promoterPseudo"
                                    className='lowercase'
                                    placeholder="Choose a pseudo for this promoter ..."
                                    // onInput={}
                                    // pattern="[a-z]"
                                    defaultValue={eventPromoter? eventPromoter.pseudo:''}
                                />

                            </div>
                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit' >Save</Button>
                            </div>
                        </form>:null
                    }

                    {(currentSubView ==='success')? 
                        <AlertComponent 
                            callBack={() => hideModal()} 
                            type='success' 
                            subject='Success' 
                            message="Congratulations, you have successfully updated this event's promoters ..."
                        />: null
                    } 

                    {(currentSubView ==='error')? 
                        <AlertComponent 
                            callBack={() => {setCurrentSubView('edit')}} 
                            type='error' 
                            subject='Failed' 
                            message='Sorry, an error occured ...' 
                        />: null
                    } 
                    
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditEventPromoterModal