import React, { useState, useEffect } from 'react'
import { useContextState } from '../../context/Context';
import { Button, Select } from 'flowbite-react';
import { useCacheApi } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import BackButton from '../shared/BackButton';



const CacheClear = () => {
    const { emptyCache, clearEventCache, clearCustomCache  } = useCacheApi();

    const { cacheCTX } = useContextState();
    const { cacheState } = cacheCTX

    document.title = `Festishare Support - Cache`

    const [currentSubView, setCurrentSubView] = useState<string>('default');
    const [message, setMessage] = useState<string>('')
    const [userId, setUserId] = useState<string>('')
    const [eventId, setEventId] = useState<string>('')
    const [cacheId, setCacheId] = useState<string>('')
    const [user, setUser] = useState<any[]>()

    const CallClearEventCache = async (userId:string, eventId:string, eventStatus:string) => {
        const apiCall = await clearEventCache({userId:userId, eventId:eventId, status:eventStatus}).then((result:any) => {
            console.log('lol: ', result)
            if (result && result.res === 'success'){
                setCurrentSubView('success')
            }
            else{
                if (result && result.res === 'failed'){setMessage(result.message)}
                else {setMessage('Sorry, there was an error clearing cache ...')}
                setCurrentSubView('error')
            }
        });
    }

    const CallClearCustomCache = async (cacheType:string, cacheId:string) => {
        const apiCall = await clearCustomCache({cacheType:cacheType, cacheId:cacheId}).then((result:any) => {
            console.log('lol: ', result)
            if (result && result.res === 'success'){
                setCurrentSubView('success')
            }
            else{
                if (result && result.res === 'failed'){setMessage(result.message)}
                else {setMessage('Sorry, there was an error clearing cache ...')}
                setCurrentSubView('error')
            }
        });
    }

    const CallEmptyCache = async (password:string) => {
        const apiCall = await emptyCache({password:password}).then((result:any) => {
            console.log('lol: ', result)
            if (result && result.res === 'success'){
                setCurrentSubView('success')
            }
            else{
                if (result && result.res === 'failed'){setMessage(result.message)}
                else {setMessage('Sorry, there was an error clearing cache ...')}
                setCurrentSubView('error')
            }
        });
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (cacheState === 'events'){
            const userIdValue = e.currentTarget.userId.value
            const eventIdValue = e.currentTarget.eventId.value
            const eventStatus = e.currentTarget.eventStatus.value
            setUserId(userIdValue)
            setEventId(eventIdValue)
            CallClearEventCache(userIdValue, eventIdValue, eventStatus)
        }
        else if (cacheState === 'custom'){
            const cacheTypeValue = e.currentTarget.cacheType.value
            const cacheIdValue = e.currentTarget.cacheId.value
            setCacheId(cacheIdValue)
            CallClearCustomCache(cacheTypeValue, cacheIdValue)
        }
        else if (cacheState === 'all'){
            const passwordValue = e.currentTarget.password.value
            CallEmptyCache(passwordValue)
        }
    };

    
    return (
        <div className="mt-20 mb-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
                

                {(currentSubView ==='loader')? <Loader />:null}

                {(currentSubView === 'default') ?
                    <form onSubmit={handleSubmit}>
                        { (cacheState === 'events') ? 
                            <div className="">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Clear Events Cache</h2>
                                <div className="mb-2 block mt-6">
                                    <label htmlFor="userId" className="block text-sm font-medium leading-6 text-gray-900">
                                        User Username
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="userId"
                                            id="userId"
                                            defaultValue={userId? userId: ''}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3 w-full flex justify-between items-center">
                                    <hr className="w-2/5" />
                                    <span className="uppercase">OR / AND</span>
                                    <hr className="w-2/5" />
                                </div>
                                <div className="mb-2 block mt-6">
                                    <label htmlFor="eventId" className="block text-sm font-medium leading-6 text-gray-900">
                                        Event ID
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="eventId"
                                            id="eventId"
                                            defaultValue={eventId? eventId: ''}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3 w-full flex justify-between items-center">
                                    <hr className="w-2/5" />
                                    <span className="uppercase">OR / AND</span>
                                    <hr className="w-2/5" />
                                </div>
                                <div className="mb-2 block mt-6">
                                    <label htmlFor="eventStatus" className="block text-sm font-medium leading-6 text-gray-900">
                                        Event Status
                                    </label>
                                    <div className="mt-2">
                                            <Select id="eventStatus" >
                                                <option></option>
                                                <option>blocked</option>
                                                <option>cancelled</option>
                                                <option>completed</option>
                                                <option>pending</option>
                                                <option>suspended</option>
                                                <option>upcoming</option>
                                            </Select>
                                    </div>
                                </div>

                                <div className="w-full flex justify-center mt-6">
                                    <Button type='submit'>Continue</Button>
                                </div>
                            </div>:null
                        }
                        { (cacheState === 'custom') ? 
                            <div className="">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Clear Custom Cache</h2>
                                <div className="mb-2 block mt-6">
                                    <label htmlFor="cacheType" className="block text-sm font-medium leading-6 text-gray-900">
                                        Cache Type
                                    </label>
                                    <div className="mt-2">
                                            <Select id="cacheType" required >
                                                <option></option>
                                                <option value={'profilePicture'}>Profile Picture</option>
                                                <option value={'picture'}>Event Picture</option>
                                                <option value={'promoter'}>Promoter</option>
                                                <option value={'promoters'}>Promoters</option>
                                            </Select>
                                    </div>
                                </div>
                                <div className="mb-2 block mt-6">
                                    <label htmlFor="cacheId" className="block text-sm font-medium leading-6 text-gray-900">
                                        Cache ID
                                    </label>
                                    <div className="mt-2">
                                    <input
                                        type="text"
                                        name="cacheId"
                                        id="cacheId"
                                        required
                                        defaultValue={cacheId? cacheId: ''}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                                <div className="w-full flex justify-center mt-6">
                                    <Button type='submit'>Continue</Button>
                                </div>
                            </div>:null
                        }
                        { (cacheState === 'all') ? 
                            <div className="">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Clear All Cache</h2>
                                <div className="mb-2 block mt-6">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                    <input
                                        type="text"
                                        name="password"
                                        id="password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>

                                <div className="w-full flex justify-center mt-6">
                                    <Button type='submit'>Continue</Button>
                                </div>
                            </div>:null
                        }
                        
                    </form>:null
                }

                {(currentSubView ==='success')? 
                    <AlertComponent 
                        callBack={() => {setCurrentSubView('default')}} 
                        type='success' 
                        subject='Success' 
                        message='Cache Cleared' 
                    />: null
                } 

                {(currentSubView ==='error')? 
                    <AlertComponent 
                        callBack={() => {setCurrentSubView('default')}} 
                        type='error' 
                        subject='Failed' 
                        message={message} 
                    />: null
                } 
            </div>
        </div>
    )
}

export default CacheClear