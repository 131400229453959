import React, { useState, useRef } from 'react'
import { useGlobalModalContext, MODAL_TYPES } from './GlobalModal';
import { useContextState } from '../../context/Context';
import { useAuth } from "../../store";
import  { Label, Button, Modal, TextInput, Checkbox, Select, Textarea } from 'flowbite-react';
import AlertComponent from '../shared/AlertComponent';
import { FaRegSadCry } from "react-icons/fa";
import Loader from '../shared/Loader';
import LoginModal from './LoginModal';




const DeleteAccountModal = () => {
    const { hideModal, store } = useGlobalModalContext();
    const { modalProps } = store || {};
    const { title, confirmBtn, eventPromoter, eventId } = modalProps || {};

    const { accountCTX } = useContextState();
    const { signedIn, setSignedIn } = accountCTX

    const [currentSubView, setCurrentSubView] = useState<string>('default');
    const [message, setMessage] = useState<string>('Sorry, an error occured ...');
    const [password, setPassword] = useState<string>('');
    const [reason, setReason] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const { deleteAccount } = useAuth();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    // const { accountCTX : {profile} } = useContextState();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')

        setPassword(e.currentTarget.password.value)
        setReason(e.currentTarget.reason.value)
        setComment(e.currentTarget.comment.value)
            
        const data = {
            password : e.currentTarget.password.value, 
            reason : e.currentTarget.reason.value, 
            comment : e.currentTarget.comment.value,
        }

        const apiCall = await deleteAccount(data).then((result:any) => {
            console.log(result)
            if (result && result.res === 'success'){setCurrentSubView('success');}
            else if (result && result.res === 'failed'){console.log(result.message); setMessage(result.message); setCurrentSubView('error')}
            else{setCurrentSubView('error')}
        });
        
    };


    return (
        <div>
            {!signedIn? 
                <LoginModal close={true} />
                :
                <Modal show={true} size="3xl" onClose={() => hideModal()}>
                    <Modal.Header>Delete FestiShare Account</Modal.Header>
                    <Modal.Body>
                        {(currentSubView ==='loader')? <Loader />:null}
                        {(currentSubView === 'default')?
                            <form onSubmit={handleSubmit}>
                                <div className="border-b border-gray-900/10 pb-6">
                                    <p className="mt-1 text-sm leading-6 text-red-600">We are very sad to see you leaving <FaRegSadCry /> </p>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">Kindly consider giving us another chance by sending us a support message instead so that we can get better. </p>

                                    <div className="mb-2 block mt-6">
                                        <Label htmlFor="password" value="Kindly provide your password" />
                                    </div>
                                    <div>
                                        <TextInput 
                                            type={isPasswordVisible ? "text" : "password"} 
                                            defaultValue={password}
                                            id="password" 
                                            required 
                                        />
                                    </div>
                                    <div className="flex justify-between mt-2">
                                        <div className="flex items-center gap-2">
                                            <Checkbox id="seePassword" onClick={()=>{isPasswordVisible ? setIsPasswordVisible(false):setIsPasswordVisible(true)}}/>
                                            <Label htmlFor="seePassword">See Password</Label>
                                        </div>
                                    </div> 

                                    <div className="border-t pt-3 mb-2 block mt-6">
                                        <Label htmlFor="reason" value="Kindly let us know why you are leaving us (we will use this information to get better" />
                                    </div>
                                    <Select id="reason" name="reason" required  defaultValue={reason}>
                                        <option></option>
                                        <option value={'The app is difficult to use'}>The app is difficult to use</option>
                                        <option value={"I don't need the app anymore"}>I don't need the app anymore</option>
                                        <option value={'The support team is not responsive'}>The support team is not responsive</option>
                                        <option value={'I had an issue at an event'}>I had an issue at an event</option>
                                        <option value={'yes'}>Other</option>
                                    </Select>

                                
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-900 mt-6">
                                        Kindly explain
                                    </label>
                                    <div className="mt-2">
                                    <Textarea
                                        name="comment"
                                        id="comment"
                                        defaultValue={comment}
                                        maxLength={512}
                                        placeholder="Enter a description for your event ..."
                                        rows={4}
                                        required
                                        className="block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                </div>
                                <div className="w-full flex justify-center mt-6">
                                    <Button type='submit' >Delete</Button>
                                </div>
                            </form>:null
                        }

                        {(currentSubView ==='success')? 
                            <AlertComponent 
                                callBack={() => hideModal()} 
                                type='success' 
                                subject='Success' 
                                message="Congratulations, you have successfully deleted your account ..."
                            />: null
                        } 

                        {(currentSubView ==='error')? 
                            <AlertComponent 
                                callBack={() => {setCurrentSubView('default')}} 
                                type='error' 
                                subject='Failed' 
                                message={message} 
                            />: null
                        } 
                        
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}

export default DeleteAccountModal