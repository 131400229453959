import React, { useState } from 'react'
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import type { LoadScriptProps } from "@react-google-maps/api"
import { country_and_states } from './CountriesAndStates'
// import { useContextState } from '../../context/Context';

interface Props{
    event: any;
    // callBack: (index: string) => void;
}

const libraries: LoadScriptProps["libraries"] = ["places"]

const AddressForm: React.FC<Props> = ({event}) => {

    // const { eventsCTX } = useContextState();
    // const { allEventsState } = eventsCTX

    const [country, setCountry] = useState<string>('United States');
    // const [address, setAddress] = useState<string>('');
    // const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    // const [postcode, setPostcode] = useState<string>('');



    //.................................... Google address 
    const [searchResult, setSearchResult] = useState<any>();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBJFXlpYGgJMVly3PabfgFnO3tnRR7Lozs",
        libraries,
    });

    function onLoad(Autocomplete:any) {
        setSearchResult(Autocomplete);
    }

    

    function onPlaceChanged() {
        if (searchResult != null) {
            const place = searchResult.getPlace();
            // const name = place.name;
            // const status = place.business_status;
            // const formattedAddress = place.formatted_address;
            // console.log(place);
            // console.log(`Name: ${name}`);
            // console.log(`Business Status: ${status}`);
            // console.log(`Formatted Address: ${formattedAddress}`);
            // console.log(place)

            var address = "";
            let city = "";
            let state = "";
            let country = "";
            let postcode = "";

            for (const component of place.address_components) {
                // @ts-ignore remove once typings fixed
                const componentType = component.types[0];

        
                switch (componentType) {
                    case "country": {
                        country = component.long_name;
                        break;
                    }

                    case "street_number": {
                        address = component.long_name;
                        break;
                    }
            
                    case "route": {
                        address += ' '
                        address += component.short_name;
                        break;
                    }
            
                    case "postal_code": {
                        postcode = component.long_name;
                        break;
                    }
            
                    case "postal_code_suffix": {
                        postcode = `${postcode}-${component.long_name}`;
                        break;
                    }

                    case "locality":{
                        city = component.long_name;
                        break;
                    }
            
                    case "administrative_area_level_1": {
                        state = component.long_name;
                    break;
                }
                
                
                }
            }

            setCountry(country);
            setState(state)

            var addressInput = document.getElementById('streetAddress')
            if (addressInput !== null){(addressInput as HTMLInputElement).value = address;}
            
            var countryInput = document.getElementById('country')
            if (countryInput !== null){(countryInput as HTMLSelectElement).value = country;}

            var cityInput = document.getElementById('city')
            if (cityInput !== null){(cityInput as HTMLInputElement).value = city;}

            var stateInput = document.getElementById('state')
            if (stateInput !== null){(stateInput as HTMLSelectElement).value = state; }

            var postcodeInput = document.getElementById('postcode')
            if (postcodeInput !== null){(postcodeInput as HTMLInputElement).value = postcode;}
            

        } else {
          alert("Please enter text");
        }
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }


    //........................................ Events handler

    const handleCountrySelect = (e:React.ChangeEvent<HTMLSelectElement>) => {
        setCountry(e.currentTarget.value);
    }

    let country_list = country_and_states['country']

    const generateStates = () => {
        var code = Object.keys(country_list).find( (key:string) => country_list[key as keyof typeof country_list] === country);
        return country_and_states['states'][code as keyof typeof country_list]
    };


  return (
    <div>
        
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Address Information</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where the event will be hosted.</p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                    <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                        Country
                    </label>
                    <div className="mt-2">
                        <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            defaultValue={event.country? event.country: 'United States'}
                            onChange={handleCountrySelect}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                            { 
                                Object.entries(country_list).map( ([countryCode , countryName]) => (
                                    <option 
                                        key={countryCode}
                                        // defaultValue={( (countryCode as keyof typeof  country_and_states['country']) === country) ? true : false}
                                    >
                                        {countryName}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    </div>

                    <div className="col-span-full">
                    <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                        Street address
                    </label>
                    <div className="mt-2">
                        <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                            <input
                                type="text"
                                name="streetAddress"
                                id="streetAddress"
                                autoComplete="street-address"
                                required
                                defaultValue={event.address? event.address: ''}
                                // onChange={handleAddress}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </Autocomplete>
                    </div>
                    </div>

                    <div className="sm:col-span-2 sm:col-start-1">
                        <label htmlFor="city" className="block text-xs font-medium leading-6 text-gray-900">
                            City
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="city"
                                id="city"
                                autoComplete="city"
                                required
                                defaultValue={event.city? event.city:''}
                                // onChange={handleCity}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="region" className="block text-xs font-medium leading-6 text-gray-900">
                            State / Province
                        </label>
                        <div className="mt-2">
                            <select
                                id="state"
                                name="state"
                                autoComplete="state-name"
                                required
                                defaultValue={event.state? event.state:''}
                                // onChange={handleStateSelect}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                                { 

                                    generateStates().map( (stateInfo) => (
                                        <option 
                                            key={stateInfo.code}
                                            selected = {state === stateInfo.name? true : false}
                                        >
                                            {stateInfo.name}
                                        </option>
                                    ))
                                }
                                
                            </select>
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="postal-code" className="block text-xs font-medium leading-6 text-gray-900">
                            ZIP&nbsp;/&nbsp;Postal&nbsp;code
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="postcode"
                                id="postcode"
                                autoComplete="postal-code"
                                defaultValue={event.postcode? event.postcode:''}
                                // onChange={handlePostcode} 
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
            </div>
        
    </div>
  )
}

export default AddressForm