import React, { useEffect, useState, createContext, useContext, useReducer } from 'react';
// import { faker } from '@faker-js/faker';
import { eventsReducer } from './Reducers';
import { Event, EventDTO, AppContextType, Payment } from "../models"
import { useAccountContext } from './AccountContext';
import { useEventsContext } from './EventsContext';
import { useSocialContext } from './SocialContext';
import { useInboxContext } from './InboxContext';
import { useNotificationContext } from './NotificationContext';
import { useCacheContext } from './CacheContext';



type ContextProps = {
    children:React.ReactNode;
}

const AppContext = createContext<AppContextType | null>(null);

// faker.seed(99)


const Context = ({ children }: ContextProps) => {
    // .......................ACCOUNT
    // const {signedIn, setSignedIn, token, setToken, refreshToken, setRefreshToken, frontSessionId, setFrontSessionId, deviceId, setDeviceId, profile, setProfile}= useAccountContext()
    const {signedIn, setSignedIn, frontSessionId, setFrontSessionId, deviceId, setDeviceId, profile, setProfile}= useAccountContext()


    // .......................EVENTS 
    const {allEventsState, dispatchAllEventsState, eventPageType, setEventPageType, eventsState, eventsDispatch } = useEventsContext()
    

    // ......................PAYMENT
    const [paymentState, setPaymentState] = useState<Payment>();


    // ...................... SOCIAL
    const {peopleState, dispatchPeopleState, peopleFilterState, peopleFilterDispatch} = useSocialContext()

    // ...................... INBOX
    const {inboxState, setInboxState} = useInboxContext()

    // ...................... NOTIFICATION
    const { 
        privateInboxUnreadMessagesCount, 
        setPrivateInboxUnreadMessagesCount, 
        eventsInboxUnreadMessagesCount, 
        setEventsInboxUnreadMessagesCount,
        privateAndEventsInboxUnreadMessagesCount, 
        setPrivateAndEventsInboxUnreadMessagesCount,
        friendRequestsCount,
        setFriendRequestsCount
    } = useNotificationContext()


    // ...................... INBOX
    const {cacheState, setCacheState} = useCacheContext()


    // ......................RENDER
    return (
        <AppContext.Provider value={{ 
            accountCTX: {signedIn, setSignedIn, frontSessionId, setFrontSessionId, deviceId, setDeviceId, profile, setProfile},
            eventsCTX : {allEventsState, dispatchAllEventsState, eventPageType, setEventPageType, eventsState, eventsDispatch },
            paymentCTX: {paymentState, setPaymentState},
            socialCTX : { peopleState, dispatchPeopleState, peopleFilterState, peopleFilterDispatch },
            inboxCTX : { inboxState, setInboxState },
            cacheCTX: {cacheState, setCacheState},  
        }}>
            {children}
        </AppContext.Provider>
    )
}

export default Context


export const useContextState =() => {
    const context = useContext(AppContext);
    if (!context){
        throw new Error(
            "useContextState must be used within Context"
        )
    }

    // console.log(context)
    return context
}