const protocol = window.location.protocol
const hostname = window.location.hostname
const port = window.location.port

const ws_schema = protocol === "http:" ? "ws:" : "wss:";

var baseUrl = `${protocol}//${hostname}`
var baseWs = `${ws_schema}//${hostname}`
if (port !== ''){
    baseUrl = `${baseUrl}:${port}`;
    // baseWs = `${baseWs}:8000/ws`
    baseWs = `${ws_schema}//127.0.0.1:8000/ws`
}


var backendUrl= '';
var backendSubDomain=''

if (hostname === 'sandbox-support.festishare.com' || hostname === 'support.festishare.com'){
    backendUrl = `${protocol}//${hostname}/test`
    backendSubDomain = `${hostname}/test`
    baseWs = `wss://${hostname}/ws`
}
else {
    backendUrl = `${protocol}//${hostname}:8000/api/v1/`
    backendSubDomain = `${hostname}`
}


export default {
    baseUrl: baseUrl,
    baseWs: baseWs,
    backendUrl: backendUrl,
    frontendSubDomain: hostname,
    backendSubDomain: backendSubDomain
};

