import React, { useState } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useContextState } from '../../context/Context'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import { useEventApi } from "../../store";
import { EventPromoter } from '../../models';
import { AiOutlineEdit, AiFillDelete } from 'react-icons/ai';
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import { Share } from '../share';
import { FaRegShareFromSquare } from 'react-icons/fa6';


interface Props{
    event : any;
    eventHasEnded? : boolean;
    callBack?: () => void;
}

const EventPromoters: React.FC<Props> = ({event, eventHasEnded}) => {
    const { eventsCTX } = useContextState();
    const { eventPageType } = eventsCTX

    const { deleteEventPromoter, updateEventPromoter } = useEventApi();

    const { showModal } = useGlobalModalContext();
    // const { modalProps } = store || {};
    const [currentSubView, setCurrentSubView] = useState<string>('default');

    // const [message, setMessage] = useState<string>('default');

    const [openModal, setOpenModal] = useState<string | undefined>();
    const props = { openModal, setOpenModal };

    const editEventPromoterModal = (eventPromoterValue:any) => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.EDIT_EVENT_PROMOTER_MODAL, { title: "Create instance form", confirmBtn: "Close", eventPromoter: eventPromoterValue, eventId: event.id });
    };

    const handleDelete = async (id:any) => {
        setCurrentSubView('loader')
        const data = {'id': id}
        var apiCall = await deleteEventPromoter(id, event.id)
        .then((result:any) => {
            if (result){setCurrentSubView('success')}else{setCurrentSubView('error')}
        });
    };

    const handleRestore = async (promoter:EventPromoter) => {
        setCurrentSubView('loader')
        promoter['status'] = 'active'

        var apiCall = await updateEventPromoter(promoter)
        .then((result:any) => {
            if (result){setCurrentSubView('success')}else{setCurrentSubView('error')}
        });
    };

    var promotersList: EventPromoter[] = []
    if (event && event.promoters){
        promotersList = event.promoters
    }

    return (
        <div className="bg-white py-24 sm:py-32">
            {(currentSubView ==='loader')? <Loader />:null}

            {(currentSubView ==='default')? 
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-600">Event Promoters</h2>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Promoters for this event
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                        Below are the promoters for this event
                    </p>
                    { (eventPageType === 'myevent') && (!eventHasEnded)?
                        <div className="mt-3 flex items-center justify-center gap-x-6">
                            <a
                            href="#"
                            onClick={(e) =>{ editEventPromoterModal(null); e.preventDefault();}}
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >Add promoter</a>
                        </div>:null
                    }
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        
                        {promotersList.map((promoter, index) => (
                            <div key={index} className={'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'} >
                                <div className=''>
                                    <div className="flex items-center justify-between gap-x-4">
                                        <h3 id={promoter.id} className={'text-lg font-semibold leading-8'}>Name: {promoter.name}</h3>
                                    </div>
                                    <p className="mt-4 text-sm leading-6 text-gray-600">Pseudo: {promoter.pseudo}</p>
                                    
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-4xl font-bold tracking-tight text-gray-900">{promoter.tickets}</span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600">tickets sold</span>
                                    </p>
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-4xl font-bold tracking-tight text-gray-900">$ {promoter.cash_balance}</span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600"> sold</span>
                                    </p>
                                    <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                        <li  className="flex gap-x-3">
                                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                            Status: {promoter.status} 
                                        </li>
                                    </ul>
                                    <img className="mx-auto h-48 w-48  md:h-56 md:w-56" src={promoter.qrCode} alt="QR Code" />
                                </div>

                                <ul role="list" className="mt-6 flex justify-center gap-x-6">
                                    <li>
                                        <div className="text-gray-400 hover:text-gray-300">
                                            <Share shareData={{ title: "Share Promoter's link", text: `This is the link of your promoter's link to ${event.name} `, url: `${promoter.link}` }}>
                                                <FaRegShareFromSquare  /> Share Link
                                            </Share>
                                        </div>
                                    </li>
                                </ul>

                                

                                    <div>
                                        <div className="border-t mt-3 flex divide-x divide-gray-200">
                                            { (promoter.pseudo !== 'default')  && (!eventHasEnded)?
                                                <div className="flex w-0 flex-1">
                                                    <a
                                                        onClick={(e) =>{ editEventPromoterModal(promoter); e.preventDefault();}}
                                                        href='#'
                                                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                                    >
                                                        <AiOutlineEdit className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        Edit
                                                    </a>
                                                </div>:null
                                            }
                                            { ((promoter.pseudo !== 'default') && (promoter.status === 'active')) && (!eventHasEnded)? 
                                                <div className="-ml-px flex w-0 flex-1">
                                                    <a
                                                        onClick={(e) =>{ handleDelete(promoter.id); e.preventDefault();}}
                                                        href='#'
                                                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                                    >
                                                        <AiFillDelete className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        Block
                                                    </a>
                                                </div> :null
                                            }
                                            { ((promoter.pseudo !== 'default') && (promoter.status !== 'active'))  && (!eventHasEnded)? 
                                                <div className="-ml-px flex w-0 flex-1">
                                                    <a
                                                        onClick={(e) =>{ handleRestore(promoter); e.preventDefault();}}
                                                        href='#'
                                                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                                    >
                                                        <AiFillDelete className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        Unblock
                                                    </a>
                                                </div> :null
                                            }
                                        </div>
                                    </div>
                                
                            </div>
                        ))}
                        { promotersList?.length === 0 ? <p>You have not yet added any promoter.</p>:null}
                    
                    </div>
                </div>:null
            }

            {(currentSubView ==='success')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='success' 
                    subject='Success' 
                    message='Congratulations, you have successfully deleted this package ...' 
                />: null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='error' 
                    subject='Failed' 
                    message='Sorry, an error occured ...' 
                />: null
            } 
        </div>
    )
}

export default EventPromoters