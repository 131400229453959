import { Fragment, useState } from 'react'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import ListWithIconsElement from './ListWithIconsElement'
// import { useContextState } from '../../context/Context'
// import { useSocialState } from '../../context/SocialContext'


function classNames(...classes:any[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props{
    titleId: string; // key of the item that should appear as title
    callBackId: string; // id name of list item that will be passed in the function callBack function when title is clicked
    callBack : (id:string) => void; // function that is called when the title is clicked
    listItems : any[]; // list that needs to display
    itemOptions? : any[]; // three dot option. Ex: [{'name':'View event', 'callBack': profileModal, 'callBackId': 'user'}]
    subtitleId?: string; // key of the item that should appear as subtitle
    subtitleName?:string; // name shown for subtitle
    status?: string; // if it needs to be filtered by status
}

const ListWithIcons: React.FC<Props> = ({ titleId, listItems, itemOptions, callBackId, callBack, subtitleId, subtitleName, status }) => {
    // console.log('listItems: ', listItems)
    const { showModal } = useGlobalModalContext();
    const [openModal, setOpenModal] = useState<string | undefined>();
    const props = { openModal, setOpenModal,  };

    var itemsToList = listItems? listItems:[]
    // console.log('listItems: ', itemsToList)
    if (status){
        if (typeof itemsToList !== 'undefined' && itemsToList.length > 0){
            itemsToList = listItems.filter((item:any) => item.status === status)
        }
    }
    // console.log(titleId)
    // console.log(itemsToList)
    return (
        <div className="mt-6 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <ul role="list" className="divide-y divide-gray-100">
                    {itemsToList.map((item:any, index) => (
                        <div key={index} className="border-b">
                            <ListWithIconsElement
                                titleId={titleId}
                                status={status}
                                callBackId={callBackId} 
                                callBack={callBack} 
                                itemOptions={itemOptions} 
                                item={item} 
                                subtitleId={subtitleId}
                                subtitleName = {subtitleName}
                            ></ListWithIconsElement>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    )
}


export default ListWithIcons
