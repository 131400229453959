import { Fragment, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import { Dialog, Popover, Transition } from '@headlessui/react'
import { useContextState } from '../../context/Context';
import { useAuth } from "../../store";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
// import { FaShoppingCart } from "react-icons/fa";
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import logo from '../../logo.png';
import { Fa0 } from 'react-icons/fa6';
import LoginModal from '../modals/LoginModal';
import AlertComponent from '../shared/AlertComponent';
import { Modal } from 'flowbite-react';



const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { showModal } = useGlobalModalContext();

  const { accountCTX } = useContextState();
  const { signedIn, setSignedIn } = accountCTX

  const { logoutCall } = useAuth();

  const [openModal, setOpenModal] = useState<string | undefined>();
  const emailInputRef = useRef<HTMLInputElement>(null)
  const props = { openModal, setOpenModal, emailInputRef };

  const [currentHeaderView, setCurrentHeaderView] = useState<string>('default');


  const handleLogOut = async () => {
    const apiCall = await logoutCall()
    .then((result:any) => {
      console.log('result: ', result)
      if (result && result.res === 'success'){
        setCurrentHeaderView('logoutSuccess')
        setMobileMenuOpen(false)
      }
      // else if (result && result.res === 'failed'){
      //   setCurrentHeaderView('logoutSuccess')
      //   setMobileMenuOpen(false)
      // }
      else{
        console.log('Logout failed ...')
      }
    });
  }

  const handleLogin = () =>{
    setCurrentHeaderView('login');
    setMobileMenuOpen(false)
  }

  const handleLoginSuccess = () =>{
    setCurrentHeaderView('edit');
    setMobileMenuOpen(false)
  }

  const loginModal = () => {
    props.setOpenModal('initial-focus');
    showModal(MODAL_TYPES.LOGIN_MODAL, { title: "Create instance form", confirmBtn: "Close" });
  };

  const profileModal = () => {
    props.setOpenModal('initial-focus');
    showModal(MODAL_TYPES.PROFILE_MODAL, { title: "Create instance form", confirmBtn: "Close", profileId:'self' });
    setMobileMenuOpen(false);
  };


  const deleteAccountModal = () => {
    props.setOpenModal('initial-focus');
    showModal(MODAL_TYPES.DELETE_ACCOUNT_MODAL, { title: "Create instance form", confirmBtn: "Close",  });
    setMobileMenuOpen(false);
  };



  return (
      <header className=" top-0 z-10 bg-white sticky">
      {/* <header className="sticky top-0 z-10 bg-white h-[48px]"> */}
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">

          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">FestiShare</span>
              <img className="h-8 w-auto" src={logo} style={{borderRadius: '35px !important'}} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <Link
                className="text-sm font-semibold leading-6 text-gray-900"
                to="/transactions" 
            >
              Transactions
            </Link> 

          </Popover.Group>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {signedIn? 
              <a onClick={handleLogOut} href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Log Out <span aria-hidden="true">&rarr;</span>
              </a> 
              :
              <a onClick={handleLogin} href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Log In <span aria-hidden="true">&rarr;</span>
              </a>
            }
            
          </div>

        </nav>
        
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 top-0 right-0 z-10 flex w-full flex-col justify-between overflow-y-none bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="p-6">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">FestiShare</span>
                  <img className="h-8 w-auto" src={logo} style={{borderRadius: '35px !important'}} alt="" />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                  </div>
                  <div className="space-y-2 py-6">

                    <Link
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      to="/transactions" 
                      onClick={ () => setMobileMenuOpen(false) }
                    >
                      Transactions
                    </Link> 
                  </div>
                  <div className="py-6">
                    {signedIn? 
                      <a onClick={handleLogOut} href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Log Out <span aria-hidden="true">&rarr;</span>
                      </a> 
                      :
                      <a onClick={handleLogin} href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Log In <span aria-hidden="true">&rarr;</span>
                      </a>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>

        {currentHeaderView === 'login'?
          <div>
            <LoginModal close={false} callBack={handleLoginSuccess} back={()=>{return setCurrentHeaderView('edit')}} closeButton={()=>{return setCurrentHeaderView('edit')}}/>
          </div>
          :null
        }

        {currentHeaderView === 'logoutSuccess'?
          <div>
            <Modal show={true} size="md" popup onClose={handleLoginSuccess} initialFocus={props.emailInputRef} >
              <Modal.Header/>
              <Modal.Body>
                <AlertComponent 
                    // callBack={()=>{return setCurrentHeaderView('default')}} 
                    callBack={()=>{return window.location.reload()}} 
                    // callBack={()=>{return }} 
                    type='success' 
                    subject='Success' 
                    message='Congratulations, you have successfully logged out ...' 
                />
              </Modal.Body>
            </Modal>
          </div>
          :null
        }

        
      </header>
  )
}
