import { Button, Checkbox, Label, TextInput } from 'flowbite-react'
import React, { useState } from 'react'
import Loader from './Loader';
import AlertComponent from './AlertComponent';

interface Props{
    eventId?: string|undefined,
    ticketId?: string|undefined,
    callBack: (credentials: {
        eventId: string;
        ticketId: string;
        password: string;
    }) => Promise<any>;
    onSuccess: ()=> void;
}

const ValidateActionWithPassword: React.FC<Props> = ({ eventId, ticketId, callBack, onSuccess }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [currentSubView, setCurrentSubView] = useState<string>('default');
    const [message, setMessage] = useState<string>('');

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')

        const data = {
            eventId: eventId?eventId:'',
            ticketId: ticketId? ticketId:'',
            password: e.currentTarget.password.value, 
        }

        // console.log(data);
        const apiCall = await callBack(data)
        .then((result:any) => {
            console.log(result)
            if (result && (result.res === 'success')){
                setMessage('You successfully cancelled your ticket. Kindly allow up to 3 business days to receive your refund if any ...'); 
                setCurrentSubView('success')
            }
            else if(result && (result.res === 'failed')){setMessage(result.message); setCurrentSubView('error')}
            else{setMessage("Sorry, an error occured ..."); setCurrentSubView('error')}
        });
        
    };

    return (
        <div>
            {(currentSubView === 'loader')? <div><Loader /></div>: null }

            {(currentSubView === 'default')?
                <form onSubmit={onSubmit}>
                    <div className="mb-2 block">
                        <Label htmlFor="password" value="Kindly enter your password and click on 'Submit' to confirm" />
                    </div>
                    <TextInput 
                        type={isPasswordVisible ? "text" : "password"} 
                        id="password" 
                        // onChange={(e) => checkpassword(e)} 
                        required 
                    />

                    <div className="flex justify-center mt-2">
                        <Checkbox id="seePassword" onClick={()=>{isPasswordVisible ? setIsPasswordVisible(false):setIsPasswordVisible(true)}}/>
                        <Label className='ml-2' htmlFor="seePassword">See Password</Label>
                    </div>

                    <div className="w-full flex justify-center mt-6">
                        <Button gradientMonochrome="success" type='submit'>Submit</Button>
                    </div>
                </form>:null
            }

            {(currentSubView ==='success')? 
                <AlertComponent 
                    callBack={() => {return onSuccess()}} 
                    type='success' 
                    subject='Success' 
                    message={message}
                />: null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='error' 
                    subject='Failed' 
                    message={message}
                />: null
            } 
        </div>
    )
}

export default ValidateActionWithPassword