import config from "../config";
import ReconnectingWebSocket from 'reconnecting-websocket';


export const useMessagesWs = () => {
    const path = config.baseWs + '/inbox/'

    const getChatSocket = (inboxId?:string)=>{
        return new WebSocket(path + inboxId + '/')
        // return new WebSocket(path)
        // return new ReconnectingWebSocket(path + inboxId + '/')
    }

    return {
        getChatSocket
    }

}