import { useState } from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useContextState } from '../../context/Context';
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { Button } from 'flowbite-react';

type IProps = { 
    callBackFree?: () => void,
    callBack: (orderId: string ) => void
}

export default function PaypalCheckout({ callBack, callBackFree }: IProps) {
    const { paymentCTX } = useContextState();
    const { paymentState, setPaymentState } = paymentCTX

    const [status, setStatus] = useState<string>('Pending');
    

    return (
        <div>
            <div className="lg:col-start-3 lg:row-end-1 mb-6">
                <h2 className="sr-only">Summary</h2>
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                    <dl className="flex flex-wrap">
                        <div className="flex-auto pl-6 pt-6">
                            <dt className="text-sm font-semibold leading-6 text-gray-900">Amount Due</dt>
                            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">$ {paymentState.value}</dd>
                        </div>
                        <div className="flex-none self-end px-6 pt-4">
                            <dt className="sr-only">Status</dt>
                            <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {status}
                            </dd>
                        </div>
                        <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                            <dt className="flex-none">
                                <span className="h-6 w-5"><b>Cost: </b></span>
                                {/* <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" /> */}
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900">$ {paymentState.amount}</dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                                <span className="h-6 w-5"><b>Commission: </b></span>
                                {/* <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" /> */}
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900">$ {paymentState.commission}</dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                                <span className="h-6 w-5"><b>Tax: </b></span>
                                {/* <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" /> */}
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900">$ {paymentState.tax}</dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                                <span className="h-6 w-5"><b>PayPal Processing Fees: </b></span>
                                {/* <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" /> */}
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900">$ {paymentState.fees}</dd>
                        </div>
                        
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                                <span className="h-6 w-5"><b>Total: </b></span>
                                {/* <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" /> */}
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900">$ {paymentState.total}</dd>
                        </div>
                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                                <span className="h-6 w-5"><b>Bonus Used: </b></span>
                                {/* <CreditCardIcon className="h-6 w-5 text-gray-400" aria-hidden="true" /> */}
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900">$ {paymentState.bonus}</dd>
                        </div>
                    </dl>
                    {(paymentState.value==0)?
                        <div>

                        </div>:null
                    
                    }
                    {/* <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                            Download receipt <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div> */}
                </div>
            </div>
            {(paymentState.value==0)?
                <div className="w-full flex justify-center mt-6">
                    <Button type='button' onClick={()=>{if (callBackFree){ return callBackFree()}}} >Continue</Button>
                </div>
                :
                <PayPalScriptProvider options={{ clientId: "AUxvYlrY8lCwpAAVaqpX6k91-qU4QqdGtErCSxGn9q3_jFH-4awwMLProHO4xafZtrDnZ7ejUWBSuyB3" }}>
                    <PayPalButtons
                    style={{ color:  'blue',
                    shape:  'pill',
                    label:  'pay',
                    height: 40 }}
                        createOrder={(data, actions: any) => {
                            // console.log(amount, currencyCode);
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            value: paymentState.value.toString(),
                                            currency_code: 'USD'
                                        },
                                    },
                                ],
                            });
                        }}
                        onApprove={(data, actions: any) => {
                            return actions.order.capture().then((details: any) => {
                                const name = details.payer.name.given_name;
                                // alert('Congratulations, you have successfully completed your payment')
                                setStatus('Paid')
                                setPaymentState({...paymentState, externalPaymentId:data.orderID})
                                callBack(data.orderID )
                            });
                        }}
                    />
                </PayPalScriptProvider>
            }
        </div>
    );
}