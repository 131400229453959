import React, { Fragment, useState} from 'react';
import { useContextState } from "../context/Context";
import { useNavigate } from "react-router-dom";
import { useAccountApi } from '../store';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { CheckIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { Modal } from 'flowbite-react';
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import LoginModal from '../components/modals/LoginModal';

function classNames(...classes:any[]) {
  return classes.filter(Boolean).join(' ')
}

const Transactions = () => {
  const { 
    getAccountTransactions
  } = useAccountApi();

  const { accountCTX} = useContextState();
  const { signedIn } = accountCTX

  const navigate = useNavigate();

  const [showResultModal, setShowResultModal] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<any>([])
  
  const [transactionIndex, setTransactionIndex] = useState<number>(0)

  document.title = `FestiShare: Discover and Host Exciting Events Near You!`

  var transactionsToList:any[] = []
  const getTransactions = async (page?:string, id?: string ) => {
    
    const apiCall = await getAccountTransactions(id? id:'')
    if (apiCall && (apiCall.res === 'success')) {
      if (page==='yes'){
        transactionsToList=apiCall.data
        setTransactions(apiCall.data)
      }
      return apiCall
    }
  };

  if (!loaded){
    setLoaded(true)
    getTransactions('yes')
  }

  const showTransaction = (index:number) => {
    setTransactionIndex(index);
    setShowResultModal(true)
  }

  const [itemOptions, setItemOptions] = useState<any>([{'name':'See Details', 'callBack': showTransaction}])

  return (
    <div>
      {!signedIn? 
        <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
        :
        <div >
            <div className="mt-6 bg-white">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h3 className='flex justify-center'>Transactions</h3>
                <ul role="list" className="divide-y divide-gray-100">
                  {transactions.map((item:any, index:any) => (
                    <li key={index} className="flex justify-between gap-x-6 py-5">
                      <div className="flex min-w-0 gap-x-4">
                          {item.icon? <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={item.icon} alt="Icon" />:null }
                          
                          <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                  <a onClick={(e) =>{showTransaction(index); e.preventDefault();}} href='#' className="hover:underline">
                                      Event Name: {item.eventName}
                                  </a>
                              </p>
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                  Amount: $ {item.amount}
                              </p>
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                  Status: {item.status}
                              </p>
                          </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-6">

                          {itemOptions? 
                            <Menu as="div" className="relative flex-none">
                                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    
                                        {Object.keys(itemOptions).map((key:any, index) => ( 
                                            <Menu.Item key={index}>
                                                {({ active }) => (
                                                    <a
                                                        onClick={(e) =>{showTransaction(index); e.preventDefault();}}
                                                        href="#"
                                                        className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        {itemOptions[key].name}<span className="sr-only">, {item.user}</span>
                                                    </a>
                                                )}
                                            </Menu.Item> 
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            : null
                          }
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
          </div>
          {transactions.length > 0?
            <Modal show={showResultModal} size="3xl" onClose={() => setShowResultModal(false)}>
              <Modal.Header>Transaction</Modal.Header>
              <Modal.Body>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3  sm:mt-5">
                  <h3  className="text-base font-semibold leading-6 text-gray-900">
                    Transaction Details
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500"></p>
                    <div className={'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'} >
                      <div className=''>
                        <div className="flex items-center justify-between gap-x-4">
                            <h3 className={'text-lg font-semibold leading-8'}>Event Name: {transactions[transactionIndex].eventName}</h3>
                        </div>
                        <p className="mt-4 text-sm leading-6 text-gray-600"><b>Transaction Id:</b></p>
                        <p className=" text-sm text-gray-600">{transactions[transactionIndex].id}</p>
                        <p className="mt-4 text-sm leading-6 text-gray-600"><b>Payment Source Id:</b></p>
                        <p className="text-sm text-gray-600">{transactions[transactionIndex].payment_id}</p>
                        <p className="mt-4 text-sm leading-6 text-gray-600"><b>Amount:</b></p>
                        <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-4xl font-bold tracking-tight text-gray-900">$ {transactions[transactionIndex].amount}</span>
                        </p>
                        <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                            <li  className="flex gap-x-3">
                                <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <b>Status: </b>{transactions[transactionIndex].status}
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </Modal.Body>
            </Modal>:null
          }

          <Footer />
          <ApplicationBar />
            
        </div>
      }
    </div>
  )
}

export default Transactions