import React, { useState } from 'react'
import { useTransactionsApi } from '../../store';
import logo from '../../logo.png';
import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input';
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';

type Props = { 
    amount: number,
    transactionType: string,
    modelId: string,
    callBack: ( ) => void
}

const PaypalPayouts: React.FC<Props> = ({amount, transactionType, modelId, callBack}) => {

    const { cashOut } = useTransactionsApi();

    const [currentSubView, setCurrentSubView] = useState<string>('default')

    const [paymentMethod, setPaymentMethod] = useState<string>('PAYPAL')
    const [idType, setIdType] = useState<string>('email')
    const [message, setMessage] = useState<string>('Sorry, there was an error ...')

    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
    const [phoneNumberConfirm, setPhoneNumberConfirm] = useState<string | undefined>('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [sameId, setSameId] = useState<boolean>(false);
    const [isdisabled, setdisability] = useState<boolean>(true);

    const handleFormChange = async (e: React.FormEvent<HTMLFormElement>) => {
        e.persist();
        // console.log(login)

        if (idType === 'EMAIL'){
            if (e.currentTarget.email.value === e.currentTarget.emailConfirm.value){
                setSameId(true); setdisability(false)
            }else{setSameId(false); setdisability(true)}
        }

        else if (idType === 'PHONE'){
            if (e.currentTarget.phone.value === e.currentTarget.phoneConfirm.value){
                if (isValidPhoneNumber(e.currentTarget.phone.value)){setSameId(true); setdisability(false)}
                else{setSameId(false); setdisability(true)}
            }else{setSameId(false); setdisability(true)}
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')

        var data = {
            userId:'',
            transactionType: transactionType,
            recipient_type: idType,
            modelId: modelId,
            recipient_wallet: paymentMethod,
            password: e.currentTarget.password.value
        }

        if (idType === 'EMAIL'){
            data['userId'] = e.currentTarget.email.value
        }
        else if (idType === 'PHONE'){
            data['userId'] = e.currentTarget.phone.value
        }
            
        const apiCall = await cashOut(data).then((result:any) => {
            console.log(result)
            if (result && result.res === 'success'){setCurrentSubView('success')}
            else if (result && result.res === 'failed'){setMessage(result.message); setCurrentSubView('error')}
            else{setCurrentSubView('error')}
        });
            
        

    };

    return (
        <div>
            <form onSubmit={handleSubmit} onChange={handleFormChange}>
                <div className="space-y-6">
                    <div className="w-full flex justify-center ">
                        <img className="flex justify-center shadow rounded-xl" src={logo} alt="Logo" width={63} />
                    </div>

                    <h3 className="flex justify-center text-lg font-medium text-gray-900 dark:text-white">FestiShare</h3>
                    <h6 className="flex justify-center text-sm font-medium text-gray-900 dark:text-white">Amount: $ {amount}</h6>
                    { currentSubView === 'default'?
                        <div >
                            <h6 className="flex justify-center text-sm font-medium text-gray-600 dark:text-white">Kindly choose your cash out method</h6>
                            <div className="flex justify-center mt-6">
                                <Button  type="button" size='md' pill gradientMonochrome="info" onClick={()=>{ setPaymentMethod('PAYPAL'); return setCurrentSubView('idType')}}>
                                    Use PayPal
                                </Button>
                            </div>
                            <div className="flex justify-center mt-6">
                                <Button  type="button" size='md' pill gradientMonochrome="info" onClick={()=>{setPaymentMethod('VENMO'); return setCurrentSubView('idType')}}>
                                    Use Venmo
                                </Button>
                            </div>
                        </div>:null
                    }

                    { currentSubView === 'idType'?
                        <div>
                            <h6 className="flex justify-center text-sm font-medium text-gray-600 dark:text-white">Kindly choose your id type for {paymentMethod}</h6>
                            <div className="flex justify-center mt-6">
                                <Button  type="button" size='md' pill outline gradientDuoTone="cyanToBlue" onClick={()=>{setIdType('EMAIL'); return setCurrentSubView('validation')}}>
                                    Use Email
                                </Button>
                            </div>
                            <div className="flex justify-center mt-6">
                                <Button  type="button" size='md' pill outline gradientDuoTone="cyanToBlue" onClick={()=>{ setIdType('PHONE'); return setCurrentSubView('validation')}}>
                                    Use Phone Number
                                </Button>
                            </div>
                            <div className="flex justify-center mt-6">
                                <Button  type="button" size='md' pill gradientMonochrome="failure" onClick={()=>{  return setCurrentSubView('default')}}>
                                    Back
                                </Button>
                            </div>
                        </div>:null
                    }

                    { currentSubView === 'validation'?
                        <div >
                            {idType==='EMAIL'?
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="email" value="Kindly provide your Paypal Email" />
                                    </div>
                                    <TextInput 
                                        id="email"  
                                        placeholder="name@domain.com"
                                        type='email' 
                                        required 
                                    />

                                    <div className="mt-6 mb-2 block">
                                        <Label htmlFor="email" value="Kindly confirm your Paypal Email" />
                                    </div>
                                    <TextInput 
                                        id="emailConfirm"  
                                        placeholder="name@domain.com" 
                                        type='email'
                                        required 
                                    />
                                    <div className='flex justify-center'>
                                        { sameId? 
                                            <h6 className='text-sm text-green-900'>Both emails match</h6>:<h6 className='text-sm text-red-900'>Both emails do not match or not valid</h6>
                                        }
                                    </div>

                                    <div className="mb-2 block">
                                        <Label htmlFor="password" value="Your password" />
                                    </div>
                                    <TextInput 
                                        type={isPasswordVisible ? "text" : "password"} 
                                        id="password" 
                                        // onChange={(e) => checkpassword(e)} 
                                        required 
                                    />

                                    <div className="flex justify-center mt-2">
                                        <Checkbox id="seePassword" onClick={()=>{isPasswordVisible ? setIsPasswordVisible(false):setIsPasswordVisible(true)}}/>
                                        <Label className='ml-2' htmlFor="seePassword">See Password</Label>
                                    </div>

                                    <div className="w-full flex justify-center mt-6">
                                        <Button gradientMonochrome="success" type='submit' disabled={isdisabled}>Submit</Button>
                                    </div>
                                </div>:null
                            }
                            {idType === 'PHONE'?
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="email" value="Kindly provide the phone number associated with your Venmo account" />
                                    </div>
                                    <PhoneInput
                                        id='phone'
                                        required
                                        international
                                        countryCallingCodeEditable={false}
                                        className="px-3 py-2 bg-white border shadow-sm border-slate-300 w-full rounded-md sm:text-sm"
                                        defaultCountry="US"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber} 
                                    />

                                    <div className="mt-6 mb-2 block">
                                        <Label htmlFor="email" value="Kindly enter the phone number again" />
                                    </div>
                                    <PhoneInput
                                        id='phoneConfirm'
                                        required
                                        international
                                        countryCallingCodeEditable={false}
                                        className="px-3 py-2 bg-white border shadow-sm border-slate-300 w-full rounded-md sm:text-sm"
                                        defaultCountry="US"
                                        value={phoneNumberConfirm}
                                        onChange={setPhoneNumberConfirm} 
                                    />

                                    <div className='flex justify-center'>
                                        { sameId? 
                                            <h6 className='text-sm text-green-900'>Both phone numbers match</h6>:<h6 className='text-sm text-red-900'>Both phone numbers do not match or not valid</h6>
                                        }
                                    </div>

                                    <div className="mt-6 mb-2 block">
                                        <Label htmlFor="password" value="Your password" />
                                    </div>
                                    <TextInput 
                                        type={isPasswordVisible ? "text" : "password"} 
                                        id="password" 
                                        // onChange={(e) => checkpassword(e)} 
                                        required 
                                    />

                                    <div className="flex justify-center mt-2">
                                        <Checkbox id="seePassword" onClick={()=>{isPasswordVisible ? setIsPasswordVisible(false):setIsPasswordVisible(true)}}/>
                                        <Label className='ml-2' htmlFor="seePassword">See Password</Label>
                                    </div>

                                    <div className="w-full flex justify-center mt-6">
                                        <Button gradientMonochrome="success" type='submit' disabled={isdisabled}>Submit</Button>
                                    </div>
                                </div>:null
                            }
                            <div className="flex justify-center mt-6">
                                <Button  type="button" size='md' pill gradientMonochrome="failure" onClick={()=>{  return setCurrentSubView('idType')}}>
                                    Back
                                </Button>
                            </div>
                        </div>:null
                    }

                    { currentSubView === 'loader'? <Loader />:null }

                    {(currentSubView ==='success')? 
                        <AlertComponent 
                            callBack={callBack} 
                            type='success' 
                            subject='Success' 
                            message='Congratulations, you have successfully cash out. Kindly allow up to 3 business days to receive your cashed out amount ...' 
                        />: null
                    } 

                    {(currentSubView ==='error')? 
                        <AlertComponent 
                            callBack={() => {return setCurrentSubView('validation')}} 
                            type='error' 
                            subject='Failed' 
                            message={message} 
                        />: null
                    } 

                </div>
            </form>
        </div>
    )
}

export default PaypalPayouts