import React from 'react'
import { Button } from 'flowbite-react'
import { MdArrowBack, MdRefresh } from "react-icons/md";


interface Props{
  callBack : () => void;
}

const BackButton: React.FC<Props> = ({callBack}) => {
  return (
    <div>
        <Button
            className='fixed bottom-[90px] left-[5px] z-30'
            onClick={()=>{return callBack()}}
            type="button"
            gradientDuoTone="purpleToBlue" 
            outline
            pill
        >   
            <MdArrowBack  size={'27px'}/>
            {/* Allow Notifications */}
        </Button>
    </div>
  )
}

export default BackButton