import "../styles.css";
import React, { Fragment } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
  InfoWindow
} from "@react-google-maps/api";
import EventCard from "../events/EventCard";
import { Link } from 'react-router-dom';
import { useContextState } from '../../context/Context'

// https://dev.to/thomasledoux1/easy-way-to-integrate-google-maps-in-react-1j6j

interface Props{
  events : any;
}

const MapFromGoogle: React.FC<Props> = ({events}) => {


  const mapRef = React.useRef<any>(null);

  const [selectedEvent, setSelectedEvent] = React.useState<any>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBJFXlpYGgJMVly3PabfgFnO3tnRR7Lozs",
  });

  const { eventsCTX } = useContextState();
    const {
      dispatchAllEventsState,
    } = eventsCTX;

  const seeEvent = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>, event:any) => {
    dispatchAllEventsState({ type: 'SET_CURRENT_EVENT', payload: {event: event} });
  };

  const onLoad = React.useCallback(
    (mapInstance:any) => {
      const bounds = new google.maps.LatLngBounds();
      events.forEach((event:any) => {
        bounds.extend(
          new google.maps.LatLng(
            event.lat,
            event.lon
          )
        );
      });
      mapRef.current = mapInstance;
      mapInstance.fitBounds(bounds);
    },
    [events]
  );

  const onClickMarker = (eventId: string) => {
    setSelectedEvent(events.find((event:any) => event.id === eventId));
  };


  return (
    <div className="App">
      {isLoaded && (
        <>
          <GoogleMap
            mapContainerClassName="c-office-overview__map"
            onLoad={onLoad}
            options={{ mapId: "90befbd8ad87d282" }}
            mapContainerStyle={{ height: "540px" }}
          >
            {events.map((event:any, index:any) => (
              <Fragment key={index}>
                {/* <Marker
                  // key={event.id}
                  icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: "#FF0000",
                    fillOpacity: Math.sqrt(1) / 15,
                    scale: Math.pow(2, 5) / 2,
                    strokeColor: "red",
                    strokeWeight: 0,
                  }}
                  onClick={() => onClickMarker(event.id)}
                  position={{
                    lat: event.lat,
                    lng: event.lon
                  }}
                /> */}
                <Circle
                  // key={index}
                  onClick={() => onClickMarker(event.id)}
                  center={{
                    lat: event.lat,
                    lng: event.lon
                  }}
                  radius={900}
                  options= {{
                    strokeColor: "red",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: Math.sqrt(30) / 15,
                  }}
                />
              </Fragment>
            ))}
            {selectedEvent && (
              <InfoWindow
                position={{ lat: selectedEvent.lat, lng: selectedEvent.lon }}
                onCloseClick={() => setSelectedEvent(null)}
              >
                <div className="opacity-100">
                  <Link to={"/event/" + selectedEvent.id} onClick={ (e) => seeEvent(e, selectedEvent) }><EventCard event={selectedEvent} /></Link>
                </div>
              
              </InfoWindow>
            )}
          </GoogleMap>
        </>
      )}
    </div>
  );
}


export default MapFromGoogle