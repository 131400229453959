import React from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { AiOutlineClose } from 'react-icons/ai';


interface Props{
    images: File[];
    callBack: (index: string) => void;
}

const ImagesSmallList: React.FC<Props> = ({images, callBack}) => {
    return (
        <div className=''>
          <h2 className="flex justify-center mt-3 text-sm font-medium text-gray-500">Selected Pictures</h2>
          <div className='flex justify-center'>
            <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
              {images.map((image, index:any) => (
                <li key={index} className="col-span-1 flex rounded-md shadow-sm">
                  <img 
                      key={index} 
                      className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white" 
                      src={URL.createObjectURL(image)} 
                      alt="Event Image" 
                  />
                  <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <a href={URL.createObjectURL(image)} className="font-medium text-gray-900 hover:text-gray-600">
                        {image.name}
                      </a>
                      <p className="text-gray-500">{image.size / 1000} KB</p>
                    </div>
                    <div className="flex-shrink-0 pr-2">
                      <button
                        type="button"
                        onClick={() => callBack(index)}
                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">Open options</span>
                        <AiOutlineClose />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
      </div>
    )
}

export default ImagesSmallList