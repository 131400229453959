import { useEffect} from 'react';
import { useApi } from "./api"
import { LoginDTO, RegisterDTO, UploadDocumentDTO, CheckAccountDTO } from "../models"
import { useContextState } from '../context/Context';
import Cookies from 'js-cookie';



export const useCacheApi = () => {
    const { accountCTX } = useContextState();
    const { signedIn, setSignedIn, profile, setProfile } = accountCTX
    const { $post, $get } = useApi()

    const emptyCache = async (credentials: {password: string}) => {
        var path = 'empty_cache/'
        const data = await $post(path, credentials, true)
        // if (data.res !== 'success'){return }
        return data
    }

    const clearEventCache = async (credentials: {eventId?: string, userId?: string, status?: string}) => {
        var path = 'clear_event_cache/'
        const data = await $post(path, credentials, true)
        // if (data.res !== 'success'){return }
        return data
    }

    const clearCustomCache = async (credentials: {cacheType: string, cacheId:string}) => {
        var path = 'clear_a_cache/'
        const data = await $post(path, credentials, true)
        // console.log(data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    


    return { emptyCache, clearEventCache, clearCustomCache }
}
