import { Modal } from 'flowbite-react'
import React from 'react'
import AlertComponent from '../shared/AlertComponent';


interface Props{
    subject: string,
    message: string;
    type: 'info' | 'success' | 'error';
    callBack: () => void;
}

const InfoModal: React.FC<Props> = ({subject, message, type, callBack}) => {
    return (
        <div>
            <Modal show={true} size="md" onClose={() => callBack()}>
                <Modal.Body>
                <div className="text-center">
                    <AlertComponent 
                        callBack={() => {return callBack()}}
                        type={type}  // info || success || error
                        subject={subject}
                        message={message}
                    />
                </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default InfoModal