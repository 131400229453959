import  { useEffect, useState } from 'react';

export const useCacheContext = () => {
    // SIGN IN
    const [cacheState, setCacheState] = useState<string>('events');
    
    return { 
        cacheState, 
        setCacheState,
    }

}