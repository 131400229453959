import { useEffect, useState, useReducer } from 'react';
import { eventsReducer, eventsFilterReducer } from './Reducers';

export const useEventsContext = () => {
    

    const pendingEventStorageValue = window.localStorage.getItem("pendingEvent");
    const eventStorageValue = window.localStorage.getItem("eventState");
    const eventsStorageValue = window.localStorage.getItem("events");
    const myEventsStorageValue = window.localStorage.getItem("myEvents");
    const myTicketsStorageValue = window.localStorage.getItem("myTickets");
    const [allEventsState, dispatchAllEventsState] = useReducer(eventsReducer, { 
        pendingEvent: (pendingEventStorageValue && pendingEventStorageValue !== 'undefined')? JSON.parse(pendingEventStorageValue) : '',
        currentEvent: (eventStorageValue && eventStorageValue !== 'undefined')? JSON.parse(eventStorageValue) : '',
        events: (eventsStorageValue && eventsStorageValue !== 'undefined')? JSON.parse(eventsStorageValue) : '',
        myevents: (myEventsStorageValue && myEventsStorageValue !== 'undefined')? JSON.parse(myEventsStorageValue) : '',
        mytickets: (myTicketsStorageValue && myTicketsStorageValue !== 'undefined')? JSON.parse(myTicketsStorageValue) : '',
        loved: []
    }); 

    // ......................PENDING EVENT
    // useEffect(() => {
    //     window.localStorage.setItem("pendingEvent", JSON.stringify(allEventsState.pendingEvent));
    // }, [allEventsState.pendingEvent]);

    // ......................EVENTS
    // useEffect(() => {
    //     window.localStorage.setItem("events", JSON.stringify(allEventsState.events));
    // }, [allEventsState.events]);

    // ......................CURRENT EVENT PAGE
    useEffect(() => {
        window.localStorage.setItem("eventState_time", (new Date()).toString());
        window.localStorage.setItem("eventState", JSON.stringify(allEventsState.currentEvent));
    }, [allEventsState.currentEvent]);


    // ......................MY EVENTS PAGE
    // useEffect(() => {
    //     window.localStorage.setItem("myEvents", JSON.stringify(allEventsState.myevents));
    // }, [allEventsState.myevents]);


    // ......................MY TICKETS PAGE
    // useEffect(() => {
    //     window.localStorage.setItem("myTickets", JSON.stringify(allEventsState.mytickets));
    // }, [allEventsState.mytickets]);


    

    
    // ......................SINGLE EVENT PAGE
    const [eventPageType, setEventPageType] = useState<string>(
        () => {
            const localStorageValue = window.localStorage.getItem("eventPageType");
            return (localStorageValue && localStorageValue !== 'undefined')? JSON.parse(localStorageValue) : '';
        }
    );
    
    useEffect(() => {
        // console.log(eventPageType)
        window.localStorage.setItem("eventPageType", JSON.stringify(eventPageType));
    }, [eventPageType]);

    

    // ......................FILTER
    const [eventsState, eventsDispatch] = useReducer(eventsFilterReducer, {
        byStock: false,
        byFastDelivery: false,
        byRating: 0,
        searchQuery: "",
        statusQuery: "upcoming",
        ticketStatusQuery: "active",
        ticketAndEventStatusQuery: {"event":"upcoming", "ticket":"active", 'operator': 'AND'},
    });


    return { allEventsState, dispatchAllEventsState, eventPageType, setEventPageType, eventsState, eventsDispatch, }

}