import  { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Profile } from '../models';

export const useNotificationContext = () => {
    // SIGN IN
    const [privateInboxUnreadMessagesCount, setPrivateInboxUnreadMessagesCount] = useState<number>(0);
    const [eventsInboxUnreadMessagesCount, setEventsInboxUnreadMessagesCount] = useState<number>(0);
    const [privateAndEventsInboxUnreadMessagesCount, setPrivateAndEventsInboxUnreadMessagesCount] = useState<number>(0);
    const [friendRequestsCount, setFriendRequestsCount] = useState<number>(0);
    
    return { 
        privateInboxUnreadMessagesCount, 
        setPrivateInboxUnreadMessagesCount,
        eventsInboxUnreadMessagesCount, 
        setEventsInboxUnreadMessagesCount,
        privateAndEventsInboxUnreadMessagesCount, 
        setPrivateAndEventsInboxUnreadMessagesCount,
        friendRequestsCount,
        setFriendRequestsCount,
    }

}