import { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContextState } from '../../context/Context'
import { useMessagesApi } from '../../store'
import ListWithIcons from '../shared/ListWithIcons'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import Loader from '../shared/Loader'
import { Modal, Button } from 'flowbite-react'
import EventDetails from '../events/eventDetails'
import Messages from './Messages'
import SecondHeader from '../headers/SecondHeader'
import ApplicationBar from '../shared/ApplicationBar'
import NewSupportMessage from './NewSupportMessage'

interface Props{
    type: string,
    modalType: string,
    setModalType: React.Dispatch<React.SetStateAction<string>>,
    showNewModal:boolean,
    setShowNewModal: React.Dispatch<React.SetStateAction<boolean>>,
    
}

// export default function InboxList() 
const InboxList: React.FC<Props> = ({ type, modalType, setModalType, showNewModal, setShowNewModal }) =>{
    const navigate = useNavigate();

    const { showModal } = useGlobalModalContext();
    const [openModal, setOpenModal] = useState<string | undefined>();
    const props = { openModal, setOpenModal,  };
    const { inboxCTX } = useContextState();

    const { inboxState } = inboxCTX;

    const { 
        getInboxes,
    } = useMessagesApi();

    // const [showNewModal, setShowNewModal] = useState<boolean>(false);
    // const [modalType, setModalType] = useState<string>('');
    const [eventId, setEventId] = useState<string>('');
    const [inboxItem, setInboxItem] = useState<any>();

    const [currentPageView, setCurrentPageView] = useState<string>('loader');
    const [inboxList, setInboxList] = useState<any[]>([]);

    const profileModal = (profileId:string) => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.PROFILE_MODAL, { title: "Create instance form", confirmBtn: "Close", profileType:'guest', profileId:profileId });
    };

    const [itemOptions, setItemOptions] = useState<any[]>([{'name':'Message', 'callBack': profileModal, 'callBackId': 'user'}]);

    
    const eventModal = (eventId:string) =>{
        setEventId(eventId);
        setModalType('Event')
        setShowNewModal(true)
    }


    const seeMessages = (messageId:string) =>{
        navigate('/messages/' + messageId)
    }

    
    const getInboxesList = (type:string) => {
        getInboxes({type:type}).then(apiCall => { 
            if (apiCall){ setInboxList(apiCall.data); }else{ setInboxList([]); }
            if (currentPageView === 'loader'){ setCurrentPageView('default')}
        });
    }


    useEffect(() => {
        if ((inboxState === 'pending')){
            setCurrentPageView('loader');
            getInboxesList('for_support')
            setItemOptions([{'name':'Message', 'callBack': seeMessages, 'callBackId': 'id'}])
            setCurrentPageView('default')
        }
        else if ((inboxState === 'current')){
            setCurrentPageView('loader')
            getInboxesList('for_support_user');
            setItemOptions([{'name':'Message', 'callBack': seeMessages, 'callBackId': 'id'}])
            setCurrentPageView('default')
        }
    }, [inboxState]);

    
    // console.log(inboxState)
    // console.log(inboxList)
    return (
        <div className="bg-white">
            {(currentPageView === 'default') && (inboxList.length > 0)?
                <div>
                    {/* <SecondHeader headerType='messages' /> */}
                    <ListWithIcons
                        titleId='subject'
                        callBackId='id'
                        callBack={seeMessages} 
                        itemOptions={itemOptions} 
                        listItems={inboxList} 
                        subtitleId='count'
                        subtitleName='Unread'
                    />
                </div>
                :
                <div >
                    {(currentPageView === 'default') && (inboxList.length === 0)?
                        <div>
                            <h6 className='mt-20' >Empty</h6>
                        </div>:null
                    }
                </div>
            }

            { (currentPageView === 'loader')? <div className='mt-12'><Loader /></div>: null }

            <Modal show={showNewModal} size="3xl" onClose={() => setShowNewModal(false)}>
                <Modal.Header>{modalType}</Modal.Header>
                <Modal.Body>
                    {(modalType === 'Event')?
                        <EventDetails eventId={eventId} />:null
                    }

                    {(modalType === 'Support Message')?
                        <NewSupportMessage callBack={()=>{getInboxesList('support'); return setShowNewModal(false)}}/>:null
                    }
                    
                </Modal.Body>
            </Modal>

            {(currentPageView !== 'messages')? 
                <ApplicationBar />:null
            }

        </div>
    )
}


export default InboxList