import { useEffect, useState } from 'react';
import './App.css';
import Header from './components/headers/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Event from './pages/Event'
import Tickets from './pages/Tickets';
import Events from './pages/Events';
import Users from './pages/Users'
import Cache from './pages/cache';
import Transactions from './pages/Transactions';
import Inbox from './pages/Inbox';
import MessagesPage from './pages/MessagesPage';
import NotFound from './pages/NotFound';
import ReloadButton from './components/shared/ReloadButton';



function App() {
  const [isApp, setIsApp] = useState<boolean>(false)

  // const { getFriendRequestsCount, getPrivateAndEventsInboxUnreadMessagesCount } = useNotificationApi()

  // useEffect(()=>{
  //   getFriendRequestsCount();
  //   getPrivateAndEventsInboxUnreadMessagesCount();
  // }, [])

  useEffect(()=>{
    if ((typeof(Storage) !== "undefined") && (typeof localStorage.getItem("app") !== "undefined")) {
      if ((localStorage.getItem("app") === 'yes') && (!isApp)){
        setIsApp(true);
      }
      else if (isApp){ setIsApp(false)}
    }
  },[])

  
  

  return (
    <BrowserRouter>
      <Header />
      <ReloadButton />
      <Routes>
          <Route path='/' element={<Events />}></Route>
          <Route path='/events' element={<Events />}></Route>
          <Route path='/tickets' element={<Tickets />}></Route> 
          <Route path='/users' element={<Users />}></Route>
          <Route path='/cache' element={<Cache />}></Route>
          <Route path='/inbox' element={<Inbox type='support'/>}></Route>

          <Route path='/transactions' element={<Transactions />}></Route>
          <Route path='/event' element={<Event />}></Route>
          <Route path='/event/:eventId' element={<Event />}></Route>
          
          {/* <Route path='/social' element={<Social />}></Route> */}
          {/* <Route path='/inbox' element={<Inbox type='private'/>}></Route> */}
          <Route path='/messages/:inboxId' element={<MessagesPage />}></Route>
          
          <Route path='*' element={<NotFound />}></Route>
      </Routes>
      
    </BrowserRouter>
  );  
}

export default App;
