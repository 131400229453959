import { useNavigate } from "react-router-dom";
import SecondHeader from '../components/headers/SecondHeader'
import EventsList from '../components/events/EventsList';
import { useContextState } from '../context/Context';
import LoginModal from '../components/modals/LoginModal';
import SearchEventModal from "../components/modals/SearchEventModal";
import CacheClear from "../components/cache/CacheClear";
import EventSearch from "../components/events/EventSearch";
import Footer from '../components/footers/Footer'
import ApplicationBar from "../components/shared/ApplicationBar";
import logo from '../logo.png';

const Cache = () => {
  const { accountCTX } = useContextState();
  const { signedIn } = accountCTX

  const navigate = useNavigate();

  document.title = `FestiShare Support: Cache!`


  return (
    <div className="">
      {!signedIn? 
          <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          :
        <div>
          <SecondHeader headerType='cache' />
          <CacheClear />
        </div>
      } 
      <Footer />
      <ApplicationBar />
    </div>
  )
}

export default Cache