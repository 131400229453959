import React, { useState, useEffect } from 'react'
import { Button } from 'flowbite-react';
import { useAccountApi } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import BackButton from '../shared/BackButton';
import UserInfo from './UserInfo';



const UserSearch = () => {
    const { getUserDetails } = useAccountApi();

    // const { ticketId } = useParams()

    const [currentSubView, setCurrentSubView] = useState<string>('search');
    const [message, setMessage] = useState<string>('')
    const [userId, setUserId] = useState<string>('')
    const [user, setUser] = useState<any[]>()

    const getUserInfo = async (userId:string) => {
        const apiCall = await getUserDetails({userId:userId}).then((result:any) => {
            console.log('lol: ', result)
            if (result && result.res === 'success'){
                // console.log(result)
                setUser(result.data)
                setUserId(result.userId)
                document.title = `Festishare Support - Users`
                setCurrentSubView('default')
            }
            else if (result && result.res === 'failed'){
                setMessage(result.message)
                setCurrentSubView('error')
            }
            else{
                setMessage('We could not find this ticket')
                setCurrentSubView('error')
            }
        });
    }

    // useEffect(() => {
    //     console.log('ticketId: ', ticketId)
    //     if (typeof ticketId !== 'undefined'){getTicketsInfo(ticketId)}
    // }, [ticketId])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const userIdValue = e.currentTarget.userId.value
        setUserId(userIdValue)
        getUserInfo(userIdValue)
    };

    
    return (
        <div className="mt-20 mb-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Information about the user</h2>

                {(currentSubView ==='loader')? <Loader />:null}

                {(currentSubView === 'search') ?
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <div className="mb-2 block mt-6">
                                <label htmlFor="userId" className="block text-sm font-medium leading-6 text-gray-900">
                                    User Id OR email OR username
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="userId"
                                    id="userId"
                                    required
                                    defaultValue={userId? userId: ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>

                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit'>Continue</Button>
                            </div>
                        </div>
                    </form>:null
                }
                { user && (currentSubView === 'default')? 
                    <div>
                        <UserInfo user={user}/>
                        <BackButton callBack={()=>{return setCurrentSubView('search')}}/>
                    </div>:null
                }

                {(currentSubView ==='error')? 
                    <AlertComponent 
                        callBack={() => {setCurrentSubView('search')}} 
                        type='error' 
                        subject='Failed' 
                        message='Ticket(s) not found ...' 
                    />: null
                } 
            </div>
        </div>
    )
}

export default UserSearch