import React, { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../shared/AlertComponent";

interface Props{
    eventId?: string,
    callBack: (credentials: {
        eventId: string;
        ticketId: string;
    }) => Promise<any>;
    onSuccess: ()=> void;
}

const Scanner: React.FC<Props> = ({eventId, callBack, onSuccess}) => {
  const navigate = useNavigate();

  const [currentSubView, setCurrentSubView] = useState<string>('default');
  const [currentResultView, setCurrentResultView] = useState<string>('default');
  const [message, setMessage] = useState<string>('');
  const [currentResult, setCurrentResult] = useState<string>('');
  const [ticket, setTicket] = useState<any>();

  const onResult = async(result: string) => {
    if (result === currentResult){return}
    setCurrentResult(result)
    const data = {
        eventId: eventId?eventId:'',
        ticketId: result,
    }
    // console.log(data);
    const apiCall = await callBack(data)
    .then((result:any) => {
        console.log(result)
        if (result && (result.res === 'success')){
            setTicket(result.data)
            setCurrentResultView('success')
        }
        else if(result && (result.res === 'failed')){setCurrentResultView('failed'); setMessage(result.message);}
        else{setMessage("Sorry, an error occured ..."); setCurrentResultView('failed')}
    });
  };

  return (
    <div className="w-full h-full flex justify-center items-center bg-primary">
        {(currentSubView === 'default')?
            <div className="w-full">
                <QrScanner
                    onDecode={onResult}
                    onError={(error: any) => console.log(error?.message)}
                    tracker={false}
                    // constraints={{ facingMode: 'user' }}
                    constraints={{ facingMode: 'environment' }}
                />
                {(ticket && (currentResultView==='success'))?
                    <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                        <li className="col-span-1 flex rounded-md shadow-sm">
                            <div
                                className={'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white' + 
                                    (((ticket.status === 'M') && (ticket.status === 'active'))?"bg-blue-800":'') + 
                                    (((ticket.gender === 'F') && (ticket.status === 'active'))?"bg-pink-800":'') + 
                                    ((ticket.status === 'scanned')?"bg-yellow-400":'') + 
                                    ((ticket.status === 'cancelled') || (ticket.status === 'requestApproved') || (ticket.status === 'blocked') || (ticket.status === 'request')?"bg-red-800":'') +
                                    ((ticket.status === 'requestApproved')? "bg-red-600":'') 
                                }
                            >
                                <img className="h-12 w-12 flex-none rounded-full" src={ticket.icon} alt="Icon" />
                                {/* {ticket.user} */}
                            </div>
                            <div className={"flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 " + 
                                    (((ticket.gender === 'M') && (ticket.status === 'active'))?"bg-blue-800":'') + 
                                    (((ticket.gender === 'F') && (ticket.status === 'active'))?"bg-pink-800":'') + 
                                    ((ticket.status === 'scanned')?"bg-yellow-400":'') + 
                                    ((ticket.status === 'cancelled') || (ticket.status === 'requestApproved') || (ticket.status === 'blocked') || (ticket.status === 'request')?"bg-red-800":'') +
                                    ((ticket.status === 'requestApproved')? "bg-red-600":'') }
                            >
                                <div className="flex-1 truncate px-4 py-2 text-sm">
                                    <a href='#' className="font-medium text-gray-900 hover:text-gray-600">
                                        {ticket.user}
                                    </a>
                                    <p className="text-gray-500">Package: {ticket.package}</p>
                                    <p className="text-gray-500">Gender: {ticket.gender}</p>
                                    <p className="text-gray-500">Status: {ticket.status}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    :
                    <p>Scanning ...</p>
                }
                {(currentResultView === 'failed')?
                    <div className="bg-red-200">
                        <p>{message}</p>
                    </div>:null
                }
            </div>
            
            :null
        }
    </div>
  );
};

export default Scanner;