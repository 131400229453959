import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useContextState } from '../../context/Context';
import FloatingLabel, { Button, Checkbox, Label, Modal, Select, Progress, Textarea } from 'flowbite-react';
import { Event, EventDTO } from '../../models';
import { useEventApi } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import validator from 'validator';
import Tickets from './Tickets';
import BackButton from '../shared/BackButton';



const TicketSearch = () => {
    const { getTickets } = useEventApi();

    // const { ticketId } = useParams()

    const [currentSubView, setCurrentSubView] = useState<string>('search');
    const [message, setMessage] = useState<string>('')
    const [eventId, setEventId] = useState<string>('')
    const [ticketId, setTicketId] = useState<string>('');
    const [eventName, setEventName] = useState<string>('')
    const [tickets, setTickets] = useState<any[]>()

    const getTicketsInfo = async (ticketId:string) => {
        const apiCall = await getTickets(ticketId).then((result:any) => {
            // console.log('lol: ', result)
            if (result && result.res === 'success'){
                // console.log(result)
                setTickets(result.data.tickets)
                setEventId(result.data.eventId)
                setEventName(result.data.eventName)
                document.title = `Festishare - Your ticket(s) to: ${result.data.eventName}`
                setCurrentSubView('default')
            }
            else if (result && result.res === 'failed'){
                setMessage(result.message)
                setCurrentSubView('error')
            }
            else{
                setMessage('We could not find this ticket')
                setCurrentSubView('error')
            }
        });
    }

    // useEffect(() => {
    //     console.log('ticketId: ', ticketId)
    //     if (typeof ticketId !== 'undefined'){getTicketsInfo(ticketId)}
    // }, [ticketId])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const ticketIdValue = e.currentTarget.ticketId.value
        setTicketId(ticketIdValue)
        getTicketsInfo(ticketIdValue)
    };

    
    return (
        <div className="mt-20 mb-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Information about the Ticket</h2>

                {(currentSubView ==='loader')? <Loader />:null}

                {(currentSubView === 'search') ?
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <div className="mb-2 block mt-6">
                                <label htmlFor="ticketId" className="block text-sm font-medium leading-6 text-gray-900">
                                    Ticket ID
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="ticketId"
                                    id="ticketId"
                                    required
                                    defaultValue={ticketId? ticketId: ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>

                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit'>Continue</Button>
                            </div>
                        </div>
                    </form>:null
                }
                { tickets && (currentSubView === 'default')? 
                    <div>
                        <Tickets eventId={eventId} eventName={eventName} tickets={tickets} />
                        <BackButton callBack={()=>{return setCurrentSubView('search')}}/>
                    </div>:null
                }

                {(currentSubView ==='error')? 
                    <AlertComponent 
                        callBack={() => {setCurrentSubView('search')}} 
                        type='error' 
                        subject='Failed' 
                        message='Ticket(s) not found ...' 
                    />: null
                } 
            </div>
        </div>
    )
}

export default TicketSearch