function updateElementOfListOfDictonaries(state: any, payload:any){
  console.log(payload)
  var res:any[] = []
  state.map((element:any) =>{  
    if (element.id === payload.id){ console.log(payload); return res.push({...element, ...payload}) }
    else {return res.push(element)}
  })
  // console.log('res: ', res)
  return res
}

// function updateElementOfListOfDictonariesFromDictionary(state: any, dictionaryId:any, payload:any){
//   if (state.id === dictionaryId){
//     state.map((element:any) =>{ return element.id === payload.id? [...element, ...payload] : element })
//   }
// }

export const eventsReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'SET_PENDING_EVENT':
        return {...state, pendingEvent: action.payload.event };

      case 'UPDATE_PENDING_EVENT':
        return {...state, pendingEvent: {...state.pendingEvent, ...action.payload.event} };

      case 'SET_CURRENT_EVENT':
        // console.log(action.payload.event)
        return {
          ...state, 
          currentEvent: action.payload.event 
        };

      case 'SET_EVENTS':
        return {...state, events: action.payload.events };

      case 'ADD_EVENT':
        return { 
          ...state,
          events: [...state.events, action.payload.event ],
          myevents: [...state.myevents, action.payload.event ], 
        };

      case 'UPDATE_EVENT':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.event.id)? {...state.currentEvent, ...action.payload.event}: state.currentEvent,
          events: state.events.map((event:any) => {return (event.id === action.payload.event.id) ? {...event, ...action.payload.event} : event}),
          myevents: state.myevents.map((event:any) => {return (event.id === action.payload.event.id) ? {...event, ...action.payload.event} : event}),  
        };
      
      case 'CANCEL_EVENT':
        console.log(action.payload)
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.eventId)? {...state.currentEvent, status:'cancelled'}: state.currentEvent,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.eventId) ? {...event, status:'cancelled'}:event} ),  
          events: state.events.map((event:any) => { return (event.id === action.payload.eventId) ? {...event, status:'cancelled'}:event} ), 
        };
          
      case 'ADD_TICKET':
        if(state.mytickets && state.mytickets.some((event:any) => event.id === action.payload.event.id)) {
          return {...state, mytickets:state.mytickets.map((event:any) =>{ return (event.id === action.payload.event.id)? {...event, tickets:action.payload.event.tickets}:event})}
        }else{return {...state}}

      case 'UPDATE_TICKET':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.eventId)? {...state.currentEvent, tickets:updateElementOfListOfDictonaries(state.currentEvent.tickets, action.payload.ticket)}: state.currentEvent,
          mytickets: state.mytickets.map((event:any) => { return (event.id === action.payload.eventId) ? {...event, tickets:updateElementOfListOfDictonaries(event.tickets, action.payload.ticket)}:event} ),  
        };

      case 'CANCEL_TICKET':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.eventId)? {...state.currentEvent, tickets:updateElementOfListOfDictonaries(state.currentEvent.tickets, action.payload.ticket)}: state.currentEvent,
          mytickets: state.mytickets.map((event:any) => { return (event.id === action.payload.eventId) ? {...event, tickets:updateElementOfListOfDictonaries(event.tickets, action.payload.ticket)}:event} ),  
        };
        
      case 'ADD_EVENT_PRICE_PACKAGE':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, price:[...state.currentEvent.price, action.payload.package]}: state.currentEvent,
          events: state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:[...event.price, action.payload.package]}:event}) ,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:[...event.price, action.payload.package]}:event} ),  
        };

      case 'APPROVE_TICKET_REQUEST':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.eventId)? {...state.currentEvent, guests:updateElementOfListOfDictonaries(state.currentEvent.guests, action.payload.guest)}: state.currentEvent,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.eventId) ? {...event, guests:updateElementOfListOfDictonaries(event.guests, action.payload.guest)}:event} ),  
        };
        
      case 'UPDATE_EVENT_PRICE_PACKAGE':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, price:updateElementOfListOfDictonaries(state.currentEvent.price, action.payload.package)}: state.currentEvent,
          events: state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:updateElementOfListOfDictonaries(event.price, action.payload.package)}:event}) ,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:updateElementOfListOfDictonaries(event.price, action.payload.package)}:event} ),  
        };
      
      case 'DELETE_EVENT_PRICE_PACKAGE':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, price:state.currentEvent.price.filter((pricePackage:any)=>(pricePackage.id !== action.payload.packageId))}: state.currentEvent,
          events: state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:event.price.filter((pricePackage:any)=>(pricePackage.id !== action.payload.packageId))}:event}) ,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:event.price.filter((pricePackage:any)=>(pricePackage.id !== action.payload.packageId))}:event} ),  
        };

      case 'ADD_EVENT_PICTURES':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, images:[...state.currentEvent.images, ...action.payload.images]}: state.currentEvent,
          events: (state.events)? state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, images:[...event.images, ...action.payload.images]}:event}): '',  
          myevents: (state.myevents)? state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, images:[...event.images, ...action.payload.images]}:event} ):'',  
        };
      
      case 'DELETE_EVENT_PICTURE':
        return { 
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, images:state.currentEvent.images.filter((image:any)=>(image.id !== action.payload.imageId))}: state.currentEvent,
          events: state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, images:event.images.filter((image:any)=>(image.id !== action.payload.imageId))}:event}) ,
          myevents: (state.myevents)? state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, images:event.images.filter((image:any)=>(image.id !== action.payload.imageId))}:event} ): '',  
        };
      
      case 'ADD_EVENT_PROMOTER':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, promoters:[...state.currentEvent.promoters, action.payload.promoter]}: state.currentEvent,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, promoters:[...event.promoters, action.payload.promoter]}:event} ),  
        };
        
      case 'UPDATE_EVENT_PROMOTER':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, promoters:updateElementOfListOfDictonaries(state.currentEvent.promoters, action.payload.promoter)}: state.currentEvent,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, promoters:updateElementOfListOfDictonaries(event.promoters, action.payload.promoter)}:event} ),  
        };
      
      case 'DELETE_EVENT_PROMOTER':
        return { 
          ...state,
          currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, promoters:state.currentEvent.promoters.filter((promoter:any)=>(promoter.id !== action.payload.promoterId))}: state.currentEvent,
          myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, promoters:event.promoters.filter((promoter:any)=>(promoter.id !== action.payload.promoterId))}:event} ),  
        };
  


      case 'SET_MY_EVENTS':
        return {...state, myevents: action.payload.events };

      case 'SET_MY_TICKETS':
        return {...state, mytickets: action.payload.events };

      case "ADD_TO_LOVED":
        return { ...state, loved: [...state.loved, { ...action.payload, qty: 1 }] };

      case "REMOVE_FROM_LOVED":
        return {
          ...state,
          loved: state.loved.filter((c:any) => c.id !== action.payload.id),
        };

      default:
        return state;
  }
}


export const eventsFilterReducer = (state:any, action:any) => {
  switch (action.type) {
    case "SORT_BY_PRICE":
      return { ...state, sort: action.payload };
    case "FILTER_BY_STOCK":
      return { ...state, byStock: !state.byStock };
    case "FILTER_BY_DELIVERY":
      return { ...state, byFastDelivery: !state.byFastDelivery };
    case "FILTER_BY_RATING":
      return { ...state, byRating: action.payload };
    case "FILTER_BY_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "FILTER_BY_STATUS":
      return { ...state, statusQuery: action.payload };
    case "FILTER_BY_TICKET_STATUS":
      return { ...state, ticketStatusQuery: action.payload };
    case "FILTER_BY_EVENT_OR_TICKET_STATUS":
      return { ...state, ticketOrEventStatusQuery: action.payload };
    case "FILTER_BY_EVENT_AND_TICKET_STATUS":
      return { ...state, ticketAndEventStatusQuery: action.payload };
    case "CLEAR_FILTERS":
      return { byStock: false, byFastDelivery: false, byRating: 0 };
    default:
      return state;
  }
};


export const peopleReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_SOCIAL_STATE':
      return {...state, socialState: action.payload };

    case 'SET_FRIENDS_STATE':
      return {...state, friendsState: action.payload };

    case 'APPEND_FRIENDS_STATE':
      // return {...state, friendsState: [...state.friendsState, ...action.payload ]};
      return {...state, friendsState: { 
        count: action.payload.count, 
        next:action.payload.next,
        previous:action.payload.previous,
        results:[...state.friendsState.results , ...action.payload.results] 
      } };

    case 'SET_REQUEST_STATE':
      return {...state, requestsState: action.payload };

    case 'APPEND_REQUEST_STATE':
      // return {...state, requestsState: [...state.requestsState , ...action.payload ]};
      return {...state, requestsState: { 
        count: action.payload.count, 
        next:action.payload.next,
        previous:action.payload.previous,
        results:[...state.requestsState.results , ...action.payload.results] 
      } };

    case 'SET_REQUEST_SENT_STATE':
      return {...state, requestsSentState: action.payload };

    case 'APPEND_REQUEST_SENT_STATE':
      // return {...state, requestsSentState: [...state.requestsSentState , ...action.payload ] };
      return {...state, requestsSentState: { 
        count: action.payload.count, 
        next:action.payload.next,
        previous:action.payload.previous,
        results:[...state.requestsSentState.results , ...action.payload.results] 
      } };

    case 'SET_FRIEND_DISCOVERY_STATE':
      // console.log((action.payload))
      return {...state, friendsDiscoveryState: action.payload };

    case 'APPEND_FRIEND_DISCOVERY_STATE':
      return {...state, friendsDiscoveryState: { 
        count: action.payload.count, 
        next:action.payload.next,
        previous:action.payload.previous,
        results:[...state.friendsDiscoveryState.results , ...action.payload.results] 
      } };

    
    case 'ADD_EVENT_PRICE_PACKAGE':
      return { 
        ...state,
        currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, price:[...state.currentEvent.price, action.payload.package]}: state.currentEvent,
        events: state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:[...event.price, action.payload.package]}:event}) ,
        myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:[...event.price, action.payload.package]}:event} ),  
      };
    
    case 'DELETE_EVENT_PRICE_PACKAGE':
      return { 
        ...state,
        currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, price:state.currentEvent.price.filter((pricePackage:any)=>(pricePackage.id !== action.payload.packageId))}: state.currentEvent,
        events: state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:event.price.filter((pricePackage:any)=>(pricePackage.id !== action.payload.packageId))}:event}) ,
        myevents: state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, price:event.price.filter((pricePackage:any)=>(pricePackage.id !== action.payload.packageId))}:event} ),  
      };

    case 'ADD_EVENT_PICTURES':
      return { 
        ...state,
        currentEvent: (state.currentEvent.id === action.payload.id)? {...state.currentEvent, images:[...state.currentEvent.images, ...action.payload.images]}: state.currentEvent,
        events: (state.events)? state.events.map((event:any) => { return (event.id === action.payload.id) ? {...event, images:[...event.images, ...action.payload.images]}:event}): '',  
        myevents: (state.myevents)? state.myevents.map((event:any) => { return (event.id === action.payload.id) ? {...event, images:[...event.images, ...action.payload.images]}:event} ):'',  
      };

    default:
      return state;
  }
}


export const peopleFilterReducer = (state:any, action:any) => {
  switch (action.type) {
    case "SORT_BY_DATE":
      return { ...state, sort: action.payload };
    case "FILTER_BY_RATING":
      return { ...state, byRating: action.payload };
    case "FILTER_BY_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "FILTER_BY_STATUS":
      return { ...state, statusQuery: action.payload };
    case "CLEAR_FILTERS":
      return { byStock: false, byFastDelivery: false, byRating: 0 };
    default:
      return state;
  }
};



export const inboxReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_INBOX_STATE':
      return {...state, inboxState: action.payload };
    case 'SET_PRIVATE_INBOX_STATE':
      return {...state, privateInboxState: action.payload };
    case 'SET_EVENTS_INBOX_STATE':
      return {...state, eventsInboxState: action.payload };
    default:
      return state;
}
}