import React from 'react'
import { Button } from 'flowbite-react'
import { MdRefresh } from "react-icons/md";

const ReloadButton = () => {
  return (
    <div>
        <Button
            className='fixed top-[150px] left-[5px] z-10'
            onClick={()=>{return window.location.reload()}}
            type="button"
            gradientDuoTone="purpleToBlue" 
            outline
            pill
        >   
            <MdRefresh  size={'27px'}/>
            {/* Allow Notifications */}
        </Button>
    </div>
  )
}

export default ReloadButton