import React, { useState } from 'react';
import { HiOutlinePhotograph, HiOutlineMicrophone } from 'react-icons/hi';

interface ChatInputProps {
    imageHandler: () => void;
    onSend: (message: string, picture: File | null, audio: File | null) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ imageHandler, onSend }) => {
  const [message, setMessage] = useState('');
  const [picture, setPicture] = useState<File | null>(null);
  const [audio, setAudio] = useState<File | null>(null);

  const handleSend = () => {
    onSend(message, picture, audio);
    setMessage('');
    setPicture(null);
    setAudio(null);
  };

  return (
    <div>              
        <div className="">
            <label  className="sr-only">Your message</label>
            <div className="flex items-center px-3 py-2 rounded-lg bg-gray-200 dark:bg-gray-700">
                {/* <label htmlFor="audio" className="flex items-center space-x-1 cursor-pointer p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                    <HiOutlineMicrophone className="w-5 h-5 text-gray-400" />
                    <input
                    type="file"
                    id="audio"
                    accept="audio/*"
                    onChange={(e) => setAudio(e.target.files?.[0] || null)}
                    className="hidden"
                    />
                </label> */}
                <label htmlFor="picture" className="flex items-center space-x-1 cursor-pointer p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                    <HiOutlinePhotograph className="w-5 h-5 text-gray-400" />
                    <input
                        type="button"
                        id="picture"
                        onClick={imageHandler}
                        className="hidden"
                    />
                </label>
                <textarea 
                    id="chatInput" 
                    rows={2} 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Your message..."
                ></textarea>
                <button 
                    type="button" 
                    onClick={handleSend}
                    className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
                    <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                    </svg>
                    <span className="sr-only">Send message</span>
                </button>
            </div>
        </div>
    </div>
  );
};

export default ChatInput;
