import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FloatingLabel, { Button, Checkbox, Label, Modal, Select, Progress, Textarea } from 'flowbite-react';
import { useContextState } from '../../context/Context'
import { Event, EventDTO } from '../../models';
import { useEventApi } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import validator from 'validator';



const EventSearch = () => {
    const navigate = useNavigate();

    const { eventsCTX } = useContextState();
    const { eventPageType, dispatchAllEventsState } = eventsCTX
    const { getEvent } = useEventApi()

    const [currentSubView, setCurrentSubView] = useState<string>('search');
    const [eventId, setEventId] = useState<string>('');
    const [eventToShow, setEventToShow] = useState<any>();


    const getEventInfo = async (eventId:string) =>{
        if (eventId){
            const apiCall = await getEvent(eventId, 'support').then((result:any) => {
                // console.log('lol: ', result)
                if (result && result.res === 'success' && result.data.length > 0){
                    console.log(result.data[0])

                    dispatchAllEventsState({ 
                        type: 'SET_CURRENT_EVENT', 
                        payload: {event: result.data[0]} 
                    });

                    setEventToShow(result.data[0])
                    document.title = `Festishare - You are invited to ${result.data[0].name}`
                    navigate('/event')
                }
                else{
                    // console.log('lal')
                    setCurrentSubView('error')
                }
            });
        }else{
            setCurrentSubView('error')
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const eventIdValue = e.currentTarget.eventId.value
        setEventId(eventIdValue)
        getEventInfo(eventIdValue)
    };

    
    return (
        <div className="mt-20 mb-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Information about the Event</h2>

                {(currentSubView ==='loader')? <Loader />:null}

                {(currentSubView === 'search') ?
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <div className="mb-2 block mt-6">
                                <label htmlFor="eventId" className="block text-sm font-medium leading-6 text-gray-900">
                                    Event ID
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="eventId"
                                    id="eventId"
                                    required
                                    defaultValue={eventId? eventId: ''}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>

                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit'>Continue</Button>
                            </div>
                        </div>
                    </form>:null
                }

                {(currentSubView ==='error')? 
                    <AlertComponent 
                        callBack={() => {setCurrentSubView('search')}} 
                        type='error' 
                        subject='Failed' 
                        message='Event not found ...' 
                    />: null
                } 
            </div>
        </div>
    )
}

export default EventSearch