import React, { useState } from 'react'
import { useEventApi } from "../../store";
import { PhotoIcon } from '@heroicons/react/24/solid'
import { AiOutlineClose } from 'react-icons/ai';
import ImagesSmallList from '../images/ImagesSmallList';
import { Button } from 'flowbite-react'
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';


interface Props{
    eventId: string;
    callBack: () => void;
}

const EventImagesUpload : React.FC<Props> = ({ eventId, callBack }) => {
    const { addEventPictures } = useEventApi();

    const [images, setImages] = useState<File[]>([])
    const [currentSubView, setCurrentSubView] = useState<string>('edit');

    const onImageChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            var filesArr = Array.prototype.slice.call(e.currentTarget.files);
            setImages(filesArr);
            // callBack(filesArr)
        }
    }

    const removeImage = (index:string) => {
        setImages(images.filter((image, imageIndex) =>  
            imageIndex.toString() !== index.toString()
        ));
    }


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader');

        const formData = new FormData();
        images.forEach(file=>{formData.append(file.name, file);});
        formData.append('eventId', eventId)

        console.log('eventId: ', eventId)

        const apiCall = await addEventPictures(formData)
        .then((result:any) => {
            console.log('res: ', result)
            if (result){setCurrentSubView('success')}else{setCurrentSubView('error')}
        });
    };

    // console.log('eventId: ', eventId)


    return (
        <div>
            <label htmlFor="cover-photo" className="mt-6 flex items-center justify-center block text-sm font-medium leading-6 text-gray-900 ">
                Add pictures for this event (You can close and do it later)
            </label>
            {(currentSubView ==='loader')? <Loader />:null }

            {(currentSubView ==='edit')?
                <form onSubmit={handleSubmit}> 
                    <div className="mt-6 flex items-center justify-center ">
                        <label  className="flex flex-col items-center justify-center relative w-full md:w-1/2 h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, GIF up to 10MB</p>
                            </div>
                            <input id="dropzone-file" type="file" className="flex items-center justify-center absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer" multiple onInput={onImageChange} required />
                        </label> 
                    </div> 
                    <ImagesSmallList images={images} callBack={removeImage} />
                    <div className="w-full flex justify-center mt-6">
                        <Button type='submit' >Save</Button>
                    </div>
                    <div className="w-full flex justify-center mt-6">
                        <Button onClick={callBack} type='button' color="failure" >Cancel</Button>
                    </div>
                </form>:null 
            }

            {(currentSubView ==='success')? 
                <AlertComponent callBack={callBack} 
                type='success' 
                subject='Success' 
                message="Congratulations, you have successfully updated this event's images ..." />: null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent callBack={() => {setCurrentSubView('edit')}} 
                type='error' 
                subject='Failed' 
                message='Sorry, an error occured ...' />: null
            } 

        </div>
    )
}

export default EventImagesUpload