import  { useState, useReducer, useEffect } from 'react';
import { peopleReducer, peopleFilterReducer } from './Reducers';


export const useSocialContext = () => {

  const initialSocialValues = { count: 0, next: null, previous:null, results:[]}

  const socialStateStorageValue = window.localStorage.getItem("socialState");
  const friendsStateStorageValue = window.localStorage.getItem("friendsState");
  const requestsStateStorageValue = window.localStorage.getItem("friendsRequestsState");
  const requestsSentStateStorageValue = window.localStorage.getItem("friendRequestsSentStateState");
  const friendsDiscoveryStateStorageValue = window.localStorage.getItem("friendsDiscoveryState"); 
  const [peopleState, dispatchPeopleState] = useReducer(peopleReducer, { 
    socialState: (socialStateStorageValue && socialStateStorageValue !== 'undefined')? socialStateStorageValue : 'friends',
    friendsState: (friendsStateStorageValue && friendsStateStorageValue !== 'undefined')? JSON.parse(friendsStateStorageValue) : initialSocialValues,
    requestsState: (requestsStateStorageValue && requestsStateStorageValue !== 'undefined')? JSON.parse(requestsStateStorageValue) : initialSocialValues,
    requestsSentState: (requestsSentStateStorageValue && requestsSentStateStorageValue !== 'undefined')? JSON.parse(requestsSentStateStorageValue) : initialSocialValues,
    friendsDiscoveryState: (friendsDiscoveryStateStorageValue && friendsDiscoveryStateStorageValue !== 'undefined')? JSON.parse(friendsDiscoveryStateStorageValue) : initialSocialValues,
  }); 

  useEffect(() => {
    window.localStorage.setItem("socialState", peopleState.socialState);
  }, [peopleState.socialState]);

  // useEffect(() => {
  //   window.localStorage.setItem("friendsState", JSON.stringify(peopleState.friendsState));
  // }, [peopleState.friendsState]);

  // useEffect(() => {
  //   window.localStorage.setItem("friendsRequestsState", JSON.stringify(peopleState.requestsState));
  // }, [peopleState.requestsState]);

  // useEffect(() => {
  //   window.localStorage.setItem("friendRequestsSentStateState", JSON.stringify(peopleState.requestsSentState));
  // }, [peopleState.requestsSentState]);

  // useEffect(() => {
  //   window.localStorage.setItem("friendsDiscoveryState", JSON.stringify(peopleState.friendsDiscoveryState));
  // }, [peopleState.friendsDiscoveryState]);


  // ......................FILTER
  const [peopleFilterState, peopleFilterDispatch] = useReducer(peopleFilterReducer, {
    byStock: false,
    byFastDelivery: false,
    byRating: 0,
    searchQuery: "",
    statusQuery: "upcoming",
    ticketStatusQuery: "active",
    ticketAndEventStatusQuery: {"event":"upcoming", "ticket":"active"}
  });
  
  return { peopleState, dispatchPeopleState, peopleFilterState, peopleFilterDispatch }

}