import React from 'react'
import logo from '../../logo.png';
import '../styles.css'

const Loader = () => {
  return (
    <div>
        <div className="space-y-6 flex animate-pulse justify-center">
		    <span className="animate rotate ">
          <div className="w-full flex justify-center ">
              <img className="flex justify-center shadow rounded-xl" src={logo} alt="Logo" width={63} />
          </div>
		    </span>
        </div>
        <p className="mt-3 flex justify-center text-gray-500 italic text-sm">Kindly Wait ...</p>
        {/* <p className="text-gray-500 italic text-sm">{loader.message}</p> */}
    </div>
  )
}

export default Loader