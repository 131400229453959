import React, { useEffect, useState } from 'react'
import { useContextState } from "../../context/Context";
import SecondHeaderButton from './SecondHeaderButton'
// import { useSocialState } from '../../context/SocialContext'

// Icons
import { GiTicket, GiThreeFriends, GiPartyPopper } from 'react-icons/gi';
import { SiPrometheus } from 'react-icons/si';
import { CiEdit } from 'react-icons/ci';
import { AiOutlinePicture, AiOutlineGlobal } from 'react-icons/ai';
import { TbUserQuestion, TbCurrentLocation } from "react-icons/tb";
import { MdApproval, MdOutlineDashboardCustomize, MdOutlinePendingActions } from "react-icons/md";



interface Props{
    headerType?:string;
    callBack?: React.Dispatch<React.SetStateAction<string>>;
}


const SecondHeader: React.FC<Props> = ({headerType, callBack}) => {
    // console.log( headerType.headerType)
    const { inboxCTX, cacheCTX } = useContextState();

    const { setCacheState } = cacheCTX;

    const { setInboxState } = inboxCTX;


    // HEADERS

    const cacheHeaders = [
        {id: 1, name: 'Events', type: 'events', searchType: 'cacheState', icon: GiPartyPopper },
        {id: 2, name: 'Custom', type: 'custom', searchType: 'cacheState', icon: MdOutlineDashboardCustomize },
        {id: 3, name: 'All', type: 'all', searchType: 'cacheState', icon: AiOutlineGlobal },
    ]

    const inboxHeaders = [
        {id: 1, name: 'Pending', type: 'pending', searchType: 'inboxState', icon: MdOutlinePendingActions },
        {id: 2, name: 'Current', type: 'current', searchType: 'inboxState', icon: TbCurrentLocation},
    ]
    
    const eventHeaders = [
        {id: 1, name: 'Event', type: 'event', searchType: 'state', icon: GiPartyPopper },
        {id: 2, name: 'Guests', type: 'guests', searchType: 'state', icon: GiThreeFriends },
        {id: 3, name: 'Approved', type: 'requestApproved', searchType: 'state', icon: MdApproval },
        {id: 4, name: 'Requests', type: 'requests', searchType: 'state', icon: TbUserQuestion },
        {id: 5, name: 'Promoters', type: 'promoters', searchType: 'state', icon: SiPrometheus },
        {id: 6, name: 'Edit', type: 'edit', searchType: 'state', icon: CiEdit },
        {id: 7, name: 'Packages', type: 'packages', searchType: 'state', icon: GiTicket },
        {id: 8, name: 'Pictures', type: 'pictures', searchType: 'state', icon: AiOutlinePicture },
    ]
   
    


    // STATES AND FUNCTIONS
    const [selected, setSelected] = useState(Number);
    const [initialLoad, setInitialLoad] = useState<boolean>(true)

    const handleHeader = (header:SecondHeaderButton) => {
        setSelected(header.id);
        if (header.searchType === 'link'){
            window.location.href = header.type
        }
        else if (header.searchType === 'inboxState'){
            setInboxState(header.type)
        }
        else if (header.searchType === 'cacheState'){
            setCacheState(header.type)
        }
        else if (header.searchType === 'state'){
            if (typeof callBack !== 'undefined'){callBack(header.type)}
        }
    };


    var thisHeader:any[] = eventHeaders;
    if (headerType && (typeof headerType === 'string')){
        if (headerType === 'event'){
            thisHeader = eventHeaders;
        }
        else if (headerType === 'cache'){
            thisHeader = cacheHeaders;
        }
        else if (headerType === 'inbox'){
            thisHeader = inboxHeaders;
        }
    }
    // console.log(thisHeader)
    var justifyCenter = ''
    if (thisHeader.length < 5){justifyCenter='justify-center'}


    return (
        <div className="sticky top-20 z-10 " style={{ backgroundColor: "white" }}>
            <div className="" >
                <div className={"sticky flex lg:flex-1 rounded-md shadow-md lg:justify-center space-x-2 overflow-x-auto w-full " + justifyCenter }> 
                    { thisHeader.map((header) => (
                        <button 
                            className={"group block flex-shrink-0 p-2 focus:bg-gray-100 bg-white-50 " }
                            key={header.id}
                            onClick={() => {setInitialLoad(false); return handleHeader(header)}}
                            style={{ backgroundColor: (header.id === selected || (initialLoad && header.id === 1 && thisHeader !== eventHeaders))  ? "gray" : "white" }}
                        >   
                            <SecondHeaderButton button1={header} key={header.id} />
                        </button>
                    ))}
                </div>
                
            </div>
        </div>
    )
    
}

export default SecondHeader