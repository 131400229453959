import { useState, useEffect } from 'react'
import EventCard from "./EventCard"
import { useContextState } from '../../context/Context'
import { useEventApi } from "../../store";
// import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from '../shared/Loader'
import { Event } from '../../models';
import { Button } from 'flowbite-react';
import { FaRegMap, FaList } from "react-icons/fa";
import MapFromGoogle from '../map/MapFromGoogle';
import InfiniteScroll from 'react-infinite-scroll-component';


interface Props{
  purpose?: string;
  eventType?: string;
  eventCallBack? : (event:Event) => void;
}
  
const EventsList: React.FC<Props> = ({purpose, eventType, eventCallBack}) => {

    const { eventsCTX } = useContextState();
    const {
      allEventsState: { events, myevents, mytickets },
      eventsState: { sort, byStock, byFastDelivery, byRating, searchQuery, statusQuery, ticketStatusQuery, ticketAndEventStatusQuery, ticketOrEventStatusQuery },
      dispatchAllEventsState,
      eventPageType,
      setEventPageType
    } = eventsCTX;

    const { 
      getMyEvents,
      getMyTickets,
      getEvents
    } = useEventApi();


    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [currentPageView, setCurrentPageView] = useState<string>('loader');
    const [pageLocation, setPageLocation] = useState<string>(window.location.pathname);
    const [currentView, setCurrentView] = useState<string>('list');
    const [resultIndex, setResultIndex] = useState<number>(1);
    const [eventsToList, setEventsToList] = useState<Event[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true); 

    
    const seeEvent = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>, event:any) => {
      // console.log('event: ', event)
      dispatchAllEventsState({ type: 'SET_CURRENT_EVENT', payload: {event: event} });
    };


    const transformEvents = (eventsToList:Event[]) => {
        
      let sortedEvents = eventsToList;
  
      if (sort) {
        sortedEvents = sortedEvents.sort((a:any, b:any) =>
          sort === "lowToHigh" ? a.price - b.price : b.price - a.price
        );
      }
  
      if (byStock) {
        sortedEvents = sortedEvents.filter((event:any) => event.inStock);
      }
  
      if (byFastDelivery) {
        sortedEvents = sortedEvents.filter((event:any) => event.fastDelivery);
      }
  
      if (byRating) {
        sortedEvents = sortedEvents.filter(
          (event:any) => event.ratings >= byRating
        );
      }
  
      if (searchQuery && (pageLocation!=='/mytickets') && (pageLocation!=='/myevents')) {
        // console.log('status: ', searchQuery)
          sortedEvents = sortedEvents.filter((event:any) => 
              event.type.toLowerCase().includes(searchQuery.toLowerCase())
          );
      }

      if (statusQuery) {
        
        sortedEvents = sortedEvents.filter((event:any) => 
          event.status.toLowerCase().includes(statusQuery)
        );
      }

      if (ticketAndEventStatusQuery) {
        sortedEvents = sortedEvents.filter((event:any) => {
          if (event.tickets && event.tickets[0] ){
            if ((ticketAndEventStatusQuery.ticket !== '') && (ticketAndEventStatusQuery.event !== '') ){
              if (ticketAndEventStatusQuery.operator === 'AND'){
                return (event.status.toLowerCase().includes(ticketAndEventStatusQuery.event) && event.tickets[0].status.toLowerCase().includes(ticketAndEventStatusQuery.ticket))
              }
              else {
                return (event.status.toLowerCase().includes(ticketAndEventStatusQuery.event) || event.tickets[0].status.toLowerCase().includes(ticketAndEventStatusQuery.ticket))
              }
            }
            else if(ticketAndEventStatusQuery.ticket !== ''){
              return event.tickets[0].status.toLowerCase().includes(ticketAndEventStatusQuery.ticket)
            }
            else if (ticketAndEventStatusQuery.event !== ''){
              return event.status.toLowerCase().includes(ticketAndEventStatusQuery.event)
            }
          }
          return true
        });
      }

      // if (currentPageView === 'loader'){ setCurrentPageView('default')}
      // console.log('res: ', sortedEvents)
      return sortedEvents;
    };


    // var pageLocation = window.location.pathname;

    useEffect(() => {
      getPageEvents();
      setIsInitialLoad(false);
    }, []);
    
    useEffect(() => {
      // setEventPageType(pageLocation === '/mytickets' ? 'myticket' : pageLocation === '/myevents' ? 'myevent' : '');
      if (pageLocation === '/mytickets'){setEventPageType('myticket')}
      else if (pageLocation === '/myevents'){setEventPageType('myevent')}
      else if (pageLocation === '/') {setEventPageType('')}
    }, [pageLocation]);

    
    const getPageEvents = async () => {
      if (currentPageView!=='loader'){setCurrentPageView('default')}
      try {

        const fetchFunction = (resultIndex:number) => {
          switch (pageLocation) {
            case '/mytickets':
              return isInitialLoad ? mytickets || getMyTickets(resultIndex) : getMyTickets(resultIndex);
            case '/myevents':
              return isInitialLoad ? myevents || getMyEvents(resultIndex) : getMyEvents(resultIndex);
            default:
              return isInitialLoad ? events || getEvents(resultIndex) : getEvents(resultIndex);
          }
        };
    
        const fetchedEvents = await Promise.resolve(fetchFunction(resultIndex));

        setResultIndex(hasMore ? resultIndex + 1 : resultIndex);
        setHasMore((typeof fetchedEvents !== 'undefined' ) && fetchedEvents.length >= 10 && purpose !== 'similar events');
        
        // if (hasMore){setResultIndex(resultIndex + 1);}
        // if (purpose === 'similar events'){setHasMore(false);}
        
        if ((typeof fetchedEvents !== 'undefined' ) && 
            (typeof eventsToList !== 'undefined' ) && 
            (fetchedEvents.length > 0) && 
            (eventsToList.length >= 0) && 
            (eventsToList.length > 0 ? (fetchedEvents[0]['id'] !== eventsToList[0]['id']) : true)
          )
        {
          setEventsToList((prevEvents) => [...prevEvents, ...fetchedEvents]); 
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        // Handle errors appropriately
      } finally {
        setCurrentPageView('default');
      }

      if (currentPageView==='loader'){setCurrentPageView('default')}
    };


    const loadMore = async () => {
      try {
        await getPageEvents();
      } catch (error) {
        console.error('Error loading more events:', error);
        // Handle errors appropriately
      }
    };

    // console.log('has: ', hasMore, resultIndex)

    return (
      <div className="bg-white">
        {(currentView === 'list')?
          <div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <InfiniteScroll
                dataLength={eventsToList.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<Loader />}
                endMessage={<p className='mt-6 mb-6'>No events to show...</p>}
              >
                <ul
                  role="list"
                  className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
                >
                  {
                      transformEvents(eventsToList).map((event:any, index:any) => {
                        if ( eventType && event.type === eventType){
                          return <Link key={index} to={"/event"} onClick={ (e) =>{ 
                            // return <Link key={index} to={"/event/" + event.id} onClick={ (e) =>{ 
                              if (eventCallBack && purpose === 'similar events') {eventCallBack(event)}; 
                              return seeEvent(e, event) 
                            }}>
                              <EventCard event={event} />
                            </Link>     
                        }
                        else if (!eventType || eventType === '') {
                          return <Link key={index} to={"/event"} onClick={ (e) => seeEvent(e, event) }><EventCard event={event} /></Link>
                            // return <Link key={index} to={"/event/" + event.id} onClick={ (e) => seeEvent(e, event) }><EventCard event={event} /></Link>
                        }
                      }
                    )
                  }
                </ul>
              </InfiniteScroll>
            </div>
            <div className='fixed inset-x-0 flex items-center justify-center bottom-[90px] z-20'>
              <Button color="blue" pill onClick={()=>{return setCurrentView('map')}}  >
                <FaRegMap size={'27px'} />
                Show Map
              </Button>
            </div>
          </div>:null

        }

        {(currentView === 'map')?
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <MapFromGoogle events={transformEvents(eventsToList)} />

            <div className='fixed inset-x-0 flex items-center justify-center bottom-[90px] z-20'>
              <Button color="blue" pill onClick={()=>{return setCurrentView('list')}}  >
                <FaList size={'27px'} />
                List View
              </Button>
            </div>

          </div>:null
        }

        {/* {currentPageView==='loader'? <div className='mt-6'><Loader /></div>:null} */}
        
        {/* <div className="bg-white py-24 sm:py-32"> */}
        
      </div>
    )
}


export default EventsList
  