// import { useEffect} from 'react';
import { useApi } from "./api"
// import { useShared } from "./shared"
import { LoginDTO, RegisterDTO, UploadDocumentDTO, CheckAccountDTO } from "../models"
import { useContextState } from '../context/Context';
import Cookies from 'js-cookie';



export const useAuth = () => {
    const { accountCTX } = useContextState();
    const { signedIn, setSignedIn, profile, setProfile } = accountCTX
    const { $post, $patch } = useApi()
    // const { setTokens } = useShared()

    const checkAccount = async (credentials: CheckAccountDTO) => {
        const data = await $post('account/action/check_if_account_exists/', credentials, false)
        return data
    }

    const sendCodeViaEmail = async (credentials: {username: string}) => {
        const data = await $post('account/action/sendCodeViaEmail/', credentials, false)
        return data
    }

    const validateCodeViaEmail = async (credentials: {code: string, type?:string}) => {
        const data = await $post('account/action/validateCodeViaEmail/', credentials, false)
        if (data.res !== "success") return
        const { tokens, email, username, firstName, lastName, phone, birth,  gender, status, profilePicture } = data
        
        if (data && data.res === "success") {
            // setTokens(tokens.access, tokens.refresh)
            setSignedIn(true);
            setProfile({ email, username, firstName, lastName, phone, birth,  gender, status, profilePicture })
        }
        return !!data.res
    }

    const resetPassword = async (credentials: {password: string}) => {
        const data = await $post('account/action/resetPassword/', credentials, false)
        return data
    }


    const getProfile = () => {
       return JSON.parse(window.localStorage.getItem("profile") as string)
    }

    const loginCall = async (credentials: LoginDTO) => {
        const data = await $post('account/signin/', credentials, false)
        // console.log(data);
        if (!data) return
        // sessionStorage.clear();
        // localStorage.clear();
        const { tokens, email, username, firstName, lastName, phone, birth,  gender, status, userType } = data
        setProfile({ email, username, firstName, lastName, phone, birth,  gender, status })
        if (tokens) {
            // setTokens(tokens.access, tokens.refresh)
            setSignedIn(true);
            setProfile({ email, username, firstName, lastName, phone, birth,  gender, status })
        }

        if (userType === 'support'){
            // console.log('support');
            return data.status
        }
        return false
    }

    const logoutCall = async () => {
        const data = await $post('account/signout/', {refresh: Cookies.get('refreshToken')}, false)
        // console.log('result: ', data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            if (data.res === 'success'){
                setSignedIn(false);
                Cookies.remove('token')
                Cookies.remove('refreshToken')
                Cookies.remove('refreshValid')
                sessionStorage.clear();
                localStorage.clear();
            }
            return data
        }

        return 
    }

    const signupCall = async (credentials: RegisterDTO) => {
        const data = await $post('users/', credentials, false)
        console.log(data);
        if (!data) return
        const { tokens, required_document, required_documents_status } = data
        // if (tokens) {
        //     console.log(tokens);
        //     setTokens(tokens.access, tokens.refresh)
        //     // setToken(tokens.access)
        //     // setRefreshToken(tokens.refresh)
        // }
        return { required_document, required_documents_status }
    }

    const uploadDocument = async (credentials: UploadDocumentDTO) => {
        const data = await $post('upload_document/', credentials, false)
        return data
    }

    const deleteAccount = async (credentials:{password:string, reason?:string, comment?:string}) => {
        const data = await $post('deleteAccount/', credentials, true)
        // console.log(data, !!data.message);
        if (!data) return
        setSignedIn(false);
        Cookies.remove('token')
        Cookies.remove('refreshToken')
        // Cookies.remove('refreshValid')
        sessionStorage.clear();
        localStorage.clear();
        return data
    }

    const updateUsername = async (credentials: {id:string, username:string}) => {
        const path = 'users/' + credentials.id + '/'
        var data = await $patch(path, credentials)
        if (data && data.res === 'success'){
            setProfile({...profile, username:credentials.username})
        }
        return data
    }

    

    return { checkAccount, sendCodeViaEmail, validateCodeViaEmail, loginCall, signupCall, resetPassword, uploadDocument, getProfile, logoutCall, deleteAccount, updateUsername }
}
