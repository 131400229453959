import { Button } from "flowbite-react";
import { type FC } from "react";

interface Props {
    children: React.ReactNode;
    shareData: ShareData;
    onSuccess?: () => void;
    onError?: (error?: unknown) => void;
    onNonNativeShare?: () => void;
    onInteraction?: () => void;
    disabled?: boolean;
}

const ShareController: FC<Props> = ({ children, shareData, onInteraction, onSuccess, onError, onNonNativeShare, disabled, }) => {
    const handleOnClick = async () => {
        onInteraction?.();
        if (navigator?.share) {
        try {
            await navigator.share(shareData);
            onSuccess?.();
        } catch (err) {
            onError?.(err);
        }
        } else {
        onNonNativeShare?.();
        }
    };
  
  return (
    <Button
      onClick={handleOnClick}
      type="button"
      disabled={disabled}
      color="gray" pill
    >
      {children}
    </Button>
  );
};



export default ShareController;