import React, { useState, useRef } from 'react'
import { useGlobalModalContext, MODAL_TYPES } from './GlobalModal';
import { useContextState } from '../../context/Context';
import { useEventApi } from "../../store";
import  { Button, Modal, TextInput } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { EventTicketPackage } from '../../models';
import EventTicketPrice from '../events/EventTicketPrice';
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';




const EditEventPackageModal = () => {
    const { hideModal, store } = useGlobalModalContext();
    const { modalProps } = store || {};
    const { title, confirmBtn, eventPackage, eventId } = modalProps || {};

    const [currentSubView, setCurrentSubView] = useState<string>('edit');
    const { updateEventTicketPackage, createEventTicketPackage } = useEventApi();

    // const { accountCTX : {profile} } = useContextState();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')
            
        const data = {
            id : '', 
            eventId: eventId,
            name : e.currentTarget.packageName.value, 
            description : e.currentTarget.packageDescription.value,
            priceMen : e.currentTarget.packagePriceMen.value, 
            priceLadies : e.currentTarget.packagePriceLadies.value,
            tickets : e.currentTarget.packageAvailableTickets.value,
            availableTickets : e.currentTarget.packageAvailableTickets.value,
        }
        
        var apiCall = ''
        if (!eventPackage){apiCall = await createEventTicketPackage(data, 'activeEvent');}
        else{data['id'] = eventPackage.id; apiCall = await updateEventTicketPackage(data, 'activeEvent');}
        
        if (apiCall){setCurrentSubView('success')}else{setCurrentSubView('error')}
        
    };


    return (
        <div>
            <Modal show={true} size="3xl" onClose={() => hideModal()}>
                <Modal.Header>Edit Package</Modal.Header>
                <Modal.Body>
                    {(currentSubView ==='loader')? <Loader />:null}
                    {(currentSubView === 'edit')?
                        <form onSubmit={handleSubmit}>
                            <EventTicketPrice eventPackage={eventPackage} />
                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit' >Save</Button>
                            </div>
                        </form>:null
                    }

                    {(currentSubView ==='success')? 
                        <AlertComponent 
                            callBack={() => hideModal()} 
                            type='success' 
                            subject='Success' 
                            message="Congratulations, you have successfully updated this event's packages ..."
                        />: null
                    } 

                    {(currentSubView ==='error')? 
                        <AlertComponent 
                            callBack={() => {setCurrentSubView('edit')}} 
                            type='error' 
                            subject='Failed' 
                            message='Sorry, an error occured ...' 
                        />: null
                    } 
                    
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditEventPackageModal