import { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import UserSearch from '../components/users/UserSearch';
import SecondHeader from '../components/headers/SecondHeader'
import EventsList from '../components/events/EventsList';
import { useContextState } from '../context/Context';
import { useGlobalModalContext } from '../components/modals/GlobalModal';
import LoginModal from '../components/modals/LoginModal';
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import logo from '../logo.png';


const Users = () => {
    const { hideModal } = useGlobalModalContext();
    const { accountCTX } = useContextState();
    const { signedIn } = accountCTX

    const [openModal, setOpenModal] = useState<string | undefined>();
    const emailInputRef = useRef<HTMLInputElement>(null)
    const props = { openModal, setOpenModal, emailInputRef };

    const handleModalToggle = () => {
        hideModal();
    };

    const navigate = useNavigate();

    document.title = `FestiShare: Discover and Host Exciting Events Near You!`


    return (
        <div>
            {!signedIn? 
                <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
                :
                <div>
                    <div className="w-full flex justify-center ">
                        <img className="flex justify-center shadow rounded-xl" src={logo} alt="Logo" width={63} />
                    </div>
                    <UserSearch   />
                </div>
            } 
            <Footer />
            <ApplicationBar />
        </div>
    )
}

export default Users