import React, { useState } from 'react'
import { Label, TextInput, Textarea } from 'flowbite-react';
import InfoModal from '../modals/InfoModal';
import { BsInfoCircle } from 'react-icons/bs';
// import { useContextState } from '../../context/Context';


interface Props{
    event? : any;
    callBack?: () => void;
}

const EventAgeRequirements: React.FC<Props> = ({event}) => {
    // const { eventsCTX } = useContextState();
    // const { pendingEvent } = eventsCTX
    // console.log('price: ', eventPackage.name)

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [modalView, setModalView] = useState<'info' | 'success' | 'error'>('info');
    const [modalMessage, setModalMessage ] = useState<string>('');

    const infoForMinAgeRequirement = "Minimum age for this event's guests"
    const infoForMaxAgeRequirement = "Maximum age for this event's guests"
    
    return (
        <div>
            <div className="border-b border-gray-900/10 pb-6">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Event Age Requirements</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Kindly add age requirements for this premium event</p>

                <div className="flex justify-between mb-2 block mt-6">
                    <Label htmlFor="eventMinAge" value="Kindly provide the minimum age" />
                    <a onClick={()=>{ setModalMessage(infoForMinAgeRequirement); return setShowInfoModal(true)}} href='#'>
                        <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                    </a>
                </div>
                <TextInput 
                    type={"number"} 
                    id="eventMinAge" 
                    min={0}
                    defaultValue={event? event.minAge:''}
                    required 
                />

                <div className="flex justify-between mb-2 block mt-6">
                    <Label htmlFor="eventMaxAge" value="Kindly provide the maximum age" />
                    <a onClick={()=>{ setModalMessage(infoForMaxAgeRequirement); return setShowInfoModal(true)}} href='#'>
                        <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                    </a>
                </div>
                <TextInput 
                    type={"number"} 
                    min={0}
                    id="eventMaxAge" 
                    defaultValue={event? event.maxAge:''}
                    required 
                />
            </div>

            { showInfoModal? 
                <InfoModal subject='Information' type={modalView} message={modalMessage} callBack={() => {return setShowInfoModal(false)}}/>: null
            }

        </div>
    )
}

export default EventAgeRequirements