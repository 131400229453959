import React, { useState } from 'react'
import { IconType } from 'react-icons';
import { Badge } from 'flowbite-react';
import { HiCheck, HiClock } from 'react-icons/hi';
// import "./styles.css"

interface SecondHeaderButton{
    id: number;
    name: string;
    type: string;
    icon: IconType;
    searchType: string;
    notificationState?: number
}

interface Props{
    button1: SecondHeaderButton;
}

const SecondHeaderButton : React.FC<Props> = ({button1}) => {

  return (
    <div className='relative items-center '>
        <div className="flex items-center justify-center">
            <center>
                <button1.icon size={20} aria-hidden="true" />
            </center>
        </div>
        <div >
            <h6 className="text-xs font-medium text-gray-700 group-hover:text-gray-900  ">{button1.name}</h6>
        </div>
        { button1.notificationState? 
            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                {button1.notificationState}
            </div>:null
        }
    </div>
  )
}

export default SecondHeaderButton