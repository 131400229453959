import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Context from './context/Context';
// import SocialContext from './context/SocialContext';
import { GlobalModal } from './components/modals/GlobalModal';
// import { useLoaderData, useNavigate, useNavigation } from 'react-router-dom';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <Root />
  <React.StrictMode>
    <Context>
      <GlobalModal>
        <App />
      </GlobalModal>
    </Context>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
