import React, {useState, useRef, useEffect} from 'react'
// import Datepicker from "react-tailwindcss-datepicker";
import FloatingLabel, { Button, Checkbox, Label, Modal, Select, Progress, Textarea } from 'flowbite-react';
import { useGlobalModalContext } from './GlobalModal';
import { EventDTO } from "../../models"
import logo from '../../logo.png';
import { IoIosArrowRoundBack } from 'react-icons/io';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input';
import AddressForm from '../address/AddressForm';
import EventTicketPrice from '../events/EventTicketPrice';
import { useEventApi } from "../../store";
import { useContextState } from '../../context/Context';
import LoginModal from './LoginModal';
import Loader from '../shared/Loader'
import PaypalCheckout from '../paypal/PaypalCheckout';
import EventInfo from '../events/EventInfo';
import AlertComponent from '../shared/AlertComponent';
import EventImagesUpload from '../events/EventImagesUpload';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { BsInfoCircle } from 'react-icons/bs';
import InfoModal from './InfoModal';
import EventAgeRequirements from '../events/EventAgeRequirements';



const Create_Event_Modal = () => {
    const { hideModal } = useGlobalModalContext();
    const [displayLoader, setDisplayLoader] = useState<boolean>(false);
    const { 
        getPendingEvent, 
        createEvent, 
        updateEvent, 
        createEventTicketPackage, 
        updateEventTicketPackage, 
        generatePendingTransactionCode,
        createIncomingPaypalTransaction,
        createIncomingFreeTransaction,
        addEventPictures
    } = useEventApi();


    const modalTopRef = useRef<null | HTMLDivElement>(null);

    const { eventsCTX, accountCTX } = useContextState();
    const { allEventsState : { pendingEvent }, dispatchAllEventsState } = eventsCTX
    const { signedIn } = accountCTX


    const [step, setStep] = useState<number>(20);
    const [currentCreateEventView, setCurrentCreateEventView] = useState<string>('eventCategory');

    const [showEventInfoSubmit, setShowEventInfoSubmit] = useState<boolean>(true);

    const [eventId, setEventId] = useState<string>('');
    const [pendingTransactionId, setPendingTransactionId ] = useState<string>('');
    
    const [isdisabled, setdisability] = useState<boolean>(false);

    const [openModal, setOpenModal] = useState<string | undefined>();
    const emailInputRef = useRef<HTMLInputElement>(null)
    const props = { openModal, setOpenModal, emailInputRef };


    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [modalView, setModalView] = useState<'info' | 'success' | 'error'>('info');
    const [modalMessage, setModalMessage ] = useState<string>('');

    const infoForPremium = 'Posting a premium event will allow you to define more parameters such as age requirements. You will be able to approve or deny guests when you post a premium event. You will be charged $20.'
    const infoForPublic = "If you select 'Yes': Everyone on the platform will be able to see your event. If you select 'No', only people that have your event's link will be able to see your event. $10 charge will apply if your event is not premium. "
    

    const getPending = async () => {
        const apiCall = await getPendingEvent();
        if (apiCall){
            setCurrentCreateEventView('eventCategory')
        }
        else{
            alert('Sorry, there was an error ...');
            hideModal();
        }

    }

    const saveFreeTransaction = async () => {
        // console.log(eventPackage.id);
        const apiCall = await createIncomingFreeTransaction({
                eventId: pendingEvent.id,
                transactionType:'event',
                transactionId: pendingTransactionId
            }
        );
        if (apiCall && (apiCall.res === 'success')) {
            setEventId(pendingEvent.id);
            dispatchAllEventsState({ type: 'SET_PENDING_EVENT', payload: {event: null} });
            setStep(100);
            setCurrentCreateEventView('pictures')
        }
    };

    const saveTransaction = async (orderID: string) => {
        // console.log(orderID, ' id ', pendingEvent.id);
        const apiCall = await createIncomingPaypalTransaction({
                orderID: orderID,
                eventId: pendingEvent.id,
                transactionType:'event',
                transactionId: pendingTransactionId
            }
        );
        if (apiCall && (apiCall.res === 'success')) {
            setEventId(pendingEvent.id);
            dispatchAllEventsState({ type: 'SET_PENDING_EVENT', payload: {event: null} });
            setStep(100);
            setCurrentCreateEventView('pictures')
        }
    };


    useEffect(() => {
        if (signedIn && (!pendingEvent || pendingEvent === '')){
            setCurrentCreateEventView('loader')
            getPending();
            
        }
        else if (!signedIn){
            dispatchAllEventsState({ type: 'SET_PENDING_EVENT', payload: {event: null} })
        }
    }, [signedIn]);

    const handleModalToggle = () => {
        hideModal();
    };

    const handleEventInfoSuccess = () => {
        setCurrentCreateEventView('address')
        setStep(60)
    };

    const handleBackButton = () => {

        if (currentCreateEventView === 'eventCategory'){
            hideModal();
        }

        else if (currentCreateEventView === 'eventInfo'){
            setCurrentCreateEventView('eventCategory')
        }

        else if (currentCreateEventView === 'address'){
            setCurrentCreateEventView('eventInfo')
        }

        else if (currentCreateEventView === 'pricePackages'){
            setCurrentCreateEventView('address');
        }
        
        else if (currentCreateEventView === 'summary'){
            if (pendingEvent.premium === 'yes'){
                setCurrentCreateEventView('age');
            }
            else {setCurrentCreateEventView('pricePackages');}
            
            setShowEventInfoSubmit(true)                        
        }
        else if (currentCreateEventView === 'payment'){
            setCurrentCreateEventView('summary');       
        }

        // else if (currentCreateEventView === 'pictures'){
        //     setCurrentCreateEventView('payment');       
        // }
    };

    const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
        e.persist();
        // console.log(login)

        if (currentCreateEventView === 'eventCategory'){
            console.log(e.currentTarget.premium.value)
            //setdisability(false);
        }

        else if (currentCreateEventView === 'eventInfo'){
            // console.log(e.currentTarget.description.value)
        }

        else if (currentCreateEventView === 'address'){
            // console.log(e.currentTarget.streetAddress.value)
        }

        else if (currentCreateEventView === 'pricePackages'){
            // console.log('lol')
        }
        
        else if (currentCreateEventView === 'payment'){

                                      
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (currentCreateEventView === 'eventCategory'){
            setCurrentCreateEventView('loader')
            const data = {
                ...pendingEvent, 
                premium:e.currentTarget.premium.value, 
                public:e.currentTarget.public.value
            }
            var apiCall = ''
            if (!pendingEvent){
                apiCall = await createEvent(data);
            }else{
                apiCall = await updateEvent(data);
            }
            if (apiCall){
                setEventId(data.id)
                setStep(40)
                setCurrentCreateEventView('eventInfo')
            }else{setCurrentCreateEventView('eventCategory'); alert('Sorry, an error occured')}
        }

        else if (currentCreateEventView === 'eventInfo'){
            setCurrentCreateEventView('loader')
            const data = {
                ...pendingEvent, 
                name: e.currentTarget.eventName.value,
                type: e.currentTarget.eventType.value,
                description: e.currentTarget.description.value,
                requirements: e.currentTarget.requirements.value,
                startDate: e.currentTarget.startDate.value,
                startTime: e.currentTarget.startTime.value,
                endDate: e.currentTarget.endDate.value,
                endTime: e.currentTarget.endTime.value,
            }
            const apiCall = await updateEvent(data);
            if (apiCall){
                setStep(60)
                setCurrentCreateEventView('address')
            }else{setCurrentCreateEventView('eventInfo'); alert('Sorry, an error occured')}
        }

        else if (currentCreateEventView === 'address'){
            setCurrentCreateEventView('loader')
            const data = {
                ...pendingEvent, 
                address: e.currentTarget.streetAddress.value,
                city: e.currentTarget.city.value,
                state: e.currentTarget.state.value,
                postcode: e.currentTarget.postcode.value,
                country: e.currentTarget.country.value,
            }
            
            const apiCall = await updateEvent(data);
            if (apiCall){
                setStep(80)
                setCurrentCreateEventView('pricePackages');
            }else{setCurrentCreateEventView('address'); alert('Sorry, an error occured')}
            
        }

        else if (currentCreateEventView === 'pricePackages'){
            setCurrentCreateEventView('loader')
            const data = {
                id : '',
                eventId : pendingEvent.id, 
                name : e.currentTarget.packageName.value, 
                description : e.currentTarget.packageDescription.value,
                priceMen : e.currentTarget.packagePriceMen.value, 
                priceLadies : e.currentTarget.packagePriceLadies.value,
                tickets : e.currentTarget.packageAvailableTickets.value,
                availableTickets : e.currentTarget.packageAvailableTickets.value,
            }
            
            var apiCall = ''
            if (!(pendingEvent.price)){
                apiCall = await createEventTicketPackage(data, 'pendingEvent');
            }else{
                data['id'] = pendingEvent.price[0].id
                apiCall = await updateEventTicketPackage(data, 'pendingEvent');
            }
            if (apiCall){
                if (pendingEvent.premium === 'yes'){setCurrentCreateEventView('age'); setStep(85)}
                else {setCurrentCreateEventView('summary'); setStep(90)}
                
                setShowEventInfoSubmit(false)
                // modalTopRef.current?.scrollIntoView({ block:'nearest' });
                modalTopRef.current?.scrollTo(0, 0);
            }else{setCurrentCreateEventView('pricePackages'); alert('Sorry, an error occured')}
        }

        else if (currentCreateEventView === 'age'){
            setCurrentCreateEventView('loader')
            const data = {
                ...pendingEvent, 
                minAge: e.currentTarget.eventMinAge.value,
                maxAge: e.currentTarget.eventMaxAge.value,
            }
            const apiCall = await updateEvent(data);
            if (apiCall){
                setStep(90)
                setCurrentCreateEventView('summary')
            }else{setCurrentCreateEventView('age'); alert('Sorry, an error occured')}
        }

        else if (currentCreateEventView === 'summary'){
            setCurrentCreateEventView('loader')
            const data = {
                ...pendingEvent, 
                premium:e.currentTarget.premium.value, 
                public:e.currentTarget.public.value,
                name: e.currentTarget.eventName.value,
                type: e.currentTarget.eventType.value,
                description: e.currentTarget.description.value,
                requirements: e.currentTarget.requirements.value,
                startDate: e.currentTarget.startDate.value,
                startTime: e.currentTarget.startTime.value,
                endDate: e.currentTarget.endDate.value,
                endTime: e.currentTarget.endTime.value,
                address: e.currentTarget.streetAddress.value,
                city: e.currentTarget.city.value,
                state: e.currentTarget.state.value,
                postcode: e.currentTarget.postcode.value,
                country: e.currentTarget.country.value,
                
            }
            const apiCall = await updateEvent(data);
            if (apiCall){
                const apiCall = await generatePendingTransactionCode({transactionType: 'event', id: data.id});
                
                if (apiCall && apiCall.res === 'success'){
                    setPendingTransactionId(apiCall.refId)
                    setStep(95)
                    setCurrentCreateEventView('payment')
                }else{setCurrentCreateEventView('summary'); alert('Sorry, an error occured')}
                
            }else{setCurrentCreateEventView('summary'); alert('Sorry, an error occured')}
                           
        }

    };


    return (
        <div>
            {!signedIn? <LoginModal close={false}/>:
                <Modal
                    // show={props.openModal === 'initial-focus'}
                    show={true}
                    size="md"
                    popup
                    // onClose={() => props.setOpenModal(undefined)}
                    onClose={handleModalToggle}
                    initialFocus={props.emailInputRef}
                >
                    <Modal.Header>
                        <IoIosArrowRoundBack className="h-6 w-12 text-gray-600 group-hover:text-indigo-600" onClick={handleBackButton} />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="w-full flex justify-center mb-3">
                            <img className="flex justify-center shadow rounded-xl" src={logo} alt="Logo" width={63} />
                        </div>
                        <Progress progress={step} size="lg" color="blue" labelProgress  />

                        { (currentCreateEventView === 'summary')? 
                            <div >
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Event Summary</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Kindly verify and make sure that the information below is correct</p>
                            </div>: null
                        }

                        { (currentCreateEventView === 'eventInfo') ? 
                            <div>
                                <EventInfo event={pendingEvent} callBack={handleEventInfoSuccess} type='pendingEvent' showEventInfoSubmit={showEventInfoSubmit} />
                            </div>: null
                        }

                        <form 
                            onSubmit={handleSubmit}
                            onChange={handleFormChange}
                        >
                            <div className="space-y-6" ref={modalTopRef}>

                                { (currentCreateEventView === 'loader')? 
                                    <Loader />: null
                                }
                                
                                { ((currentCreateEventView === 'eventCategory') || (currentCreateEventView === 'summary')) &&  
                                    <div>
                                        {!(currentCreateEventView === 'summary')?
                                            <h2 className="flex justify-center text-base font-semibold leading-7 text-gray-900">Add an Event</h2>:null
                                        }
                                        
                                        <div className=" border-gray-900/10 pb-6 mb-6 mt-6">
                                            <div className="flex justify-between mb-2 block">
                                                <Label htmlFor="premium" value="Is this a premium event? " />
                                                <a onClick={()=>{ setModalMessage(infoForPremium); return setShowInfoModal(true)}} href='#'>
                                                    <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                                                </a>
                                            </div>
                                            <Select
                                                id="premium"
                                                name="premium"
                                                required
                                                defaultValue={(pendingEvent && pendingEvent.premium)? pendingEvent.premium: null}
                                            >
                                                <option></option>
                                                <option value={'no'}>No</option>
                                                <option value={'yes'}>Yes</option>
                                            </Select>
                                        </div>

                                        <div className="border-b border-gray-900/10 pb-6 mb-6 mt-6">
                                            <div className="flex justify-between mb-2 block">
                                                <Label htmlFor="public" value="Is this event public ? " />
                                                <a onClick={()=>{ setModalMessage(infoForPublic); return setShowInfoModal(true)}} href='#'>
                                                    <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                                                </a>
                                            </div>
                                            <Select
                                                id="public"
                                                required
                                                defaultValue={(pendingEvent && pendingEvent.public)? pendingEvent.public: null}
                                            >
                                                <option></option>
                                                <option value={'no'}>No</option>
                                                <option value={'yes'}>Yes</option>
                                            </Select>
                                        </div>
                                    </div>
                                }

                                { (currentCreateEventView === 'summary') ? 
                                    <div>
                                        <EventInfo event={pendingEvent} callBack={handleEventInfoSuccess} type='pendingEvent' showEventInfoSubmit={false} />
                                    </div>: null
                                }

                                {((currentCreateEventView === 'address') || (currentCreateEventView === 'summary'))? 
                                    <div>
                                        <AddressForm event={pendingEvent} />
                                    </div>: null
                                }

                                {((currentCreateEventView === 'pricePackages')  || (currentCreateEventView === 'summary'))?
                                    <div>
                                        <EventTicketPrice eventPackage={pendingEvent.price? pendingEvent.price[0]:null} />
                                    </div>: null
                                }

                                {((currentCreateEventView === 'age')  || ((currentCreateEventView === 'summary') && (pendingEvent.premium === 'yes')))?
                                    <div>
                                        <EventAgeRequirements event={pendingEvent} />
                                    </div>: null
                                }

                                {(currentCreateEventView === 'payment')?
                                    <div>
                                        <PaypalCheckout callBackFree={saveFreeTransaction} callBack={saveTransaction} />
                                    </div>: null
                                }

                                { (!(currentCreateEventView === 'payment') && !(currentCreateEventView === 'eventInfo'))?
                                    <div className="w-full flex justify-center mt-6">
                                        <Button type='submit' disabled={isdisabled} >Continue</Button>
                                    </div>: null
                                }

                                {/* <div className="flex justify-between text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Not registered?&nbsp;
                                    <a href="/modal" className="text-cyan-700 hover:underline dark:text-cyan-500">
                                        Create account
                                    </a>
                                </div> */}
                            </div>
                        </form>

                        {currentCreateEventView === 'pictures'?
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-green-900">The payment was successful ...</h2>
                                <EventImagesUpload  callBack={handleModalToggle} eventId={eventId}/>
                            </div>:null
                        } 
                    </Modal.Body>
                </Modal>
            }

            { showInfoModal? 
                <InfoModal subject='Information' type={modalView} message={modalMessage} callBack={() => {return setShowInfoModal(false)}}/>: null
            }

        </div>
    ) 
}

export default Create_Event_Modal