import React, { useEffect, useState } from 'react'
import { useContextState } from '../context/Context';
import { useNavigate } from "react-router-dom";
import InboxList from '../components/messages/InboxList'
import LoginModal from '../components/modals/LoginModal';
// import { useNotificationApi } from '../store';
import SecondHeader from '../components/headers/SecondHeader';
import { Button } from 'flowbite-react';
import logo from '../logo.png';

interface Props{
  type: string,
}

const Inbox: React.FC<Props> = ({ type }) => {
  const { accountCTX, inboxCTX } = useContextState();
  const { signedIn } = accountCTX

  const navigate = useNavigate();

  const [modalType, setModalType] = useState<string>('');
  const [showNewModal, setShowNewModal] = useState<boolean>(false);

  document.title = `FestiShare Support: Messages`

  return (
    <div>
      {!signedIn? 
        <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
        :
        <div className=''>
          <SecondHeader headerType='inbox' />
          <InboxList 
            type={type} 
            modalType={modalType}
            setModalType={setModalType}
            showNewModal={showNewModal}
            setShowNewModal={setShowNewModal}
          />
        </div>
      }
    </div>
  )
}

export default Inbox