import { useNavigate } from "react-router-dom";
import SecondHeader from '../components/headers/SecondHeader'
import EventsList from '../components/events/EventsList';
import { useContextState } from '../context/Context';
import LoginModal from '../components/modals/LoginModal';
import SearchEventModal from "../components/modals/SearchEventModal";
import EventSearch from "../components/events/EventSearch";
import Footer from '../components/footers/Footer'
import ApplicationBar from "../components/shared/ApplicationBar";
import logo from '../logo.png';

const Events = () => {
  const { accountCTX } = useContextState();
  const { signedIn } = accountCTX

  const navigate = useNavigate();

  document.title = `FestiShare Support: Events`


  return (
    <div className="">
      {!signedIn? 
          <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          :
        <div>
          <div className="w-full flex justify-center ">
              <img className="flex justify-center shadow rounded-xl" src={logo} alt="Logo" width={63} />
          </div>
          {/* <SecondHeader headerType='myevents' /> */}
          <EventSearch   />
        </div>
      } 
      <Footer />
      <ApplicationBar />
    </div>
  )
}

export default Events