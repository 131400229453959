import axios, { AxiosError, AxiosHeaders } from "axios"
import { useContextState } from '../context/Context';
import Cookies from 'js-cookie';
import config from "../config";
import { useState } from "react";

// import { getCookie, setCookie } from 'typescript-cookie'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;



export const useApi = () => {
    const { accountCTX } = useContextState();
    const { setSignedIn } = accountCTX

    // const [isRefreshingToken, setIsRefreshingToken] = useState<boolean>(false)

    const api = axios.create({
        baseURL: config.backendUrl,
    });

    const refreshApi = axios.create({
        baseURL: config.backendUrl,
    });

    // var temporaryRefreshToken = refreshToken
    const delay = (milliseconds:number) => new Promise(resolve => setTimeout(resolve, milliseconds));

    

    api.interceptors.response.use(
        (response) => response,  // Pass through successful responses
        async (error) => {
            const originalRequest = error.config;
            // console.log('starting token refresh')
            // console.log('original: ', originalRequest)
      
            if (error.response?.status === 401 && !originalRequest._retry) {
                setSignedIn(false)
                // originalRequest._retry = true;
        
                // try {
                //     // const credentials = { refresh: refreshToken };
                //     const response = await refreshTokenCall();
                //     // console.log('lol: ', response)
                //     if (response) {
                //         // Retry directly with updated headers
                //         error.config.headers.Authorization = `Bearer ${response}`;
                //         api.defaults.headers['Authorization'] = `Bearer ${response}`;
                //         return refreshApi(error.config);
                //     } else {
                //         console.error('Refresh failed');
                //         // Handle refresh failure (e.g., redirect to login)
                //         setSignedIn(false)
                //     }
                // } catch (error) {
                //     console.error('Refresh error');
                //     // console.error(error);
                //     setSignedIn(false)
                //     // Handle refresh errors (e.g., remove refresh token, redirect to login)
                // }
            } 
            // else if (error.response?.status === 401) {
            //     // Handle other 401 errors (e.g., invalid token)
            //     setSignedIn(false)
            //     // console.log(error)
            //     console.log('error')
            // }
        
            return Promise.reject(error);
        }
    );

    const getHeaders = async(authentication: boolean, secured?: boolean) => {
        // console.log(token)
        const headers: AxiosHeaders = new AxiosHeaders()
        // if (authentication) {
        //     headers.setAuthorization('Bearer ' + Cookies.get('token'))
        // }
        headers.setContentType('multipart/form-data')
        // console.log(headers.toJSON());
        return headers
    }

    const makeFormData = (obj: any) => {
        const formData = new FormData()
        Object.keys(obj).forEach(key => formData.append(key, obj[key]))
        return formData
    }

    const setUserId = (id: string) => {
        sessionStorage.setItem('userId', id)
    }

    const $get = async (path: string, authentication:boolean, body= {}, buildForm=true, ) => {
        
        try {
            const { data } = await api.get(path, { headers: await getHeaders(authentication), params:body, withCredentials: true })
            return data
        } catch (err: any) {
            console.log(err.message)
        }
    }

    const $post = async (path: string, body: any, authentication = true, buildForm=true, secured =false) => {

        var formData = body
        if (buildForm){formData = makeFormData(body)}
         
        // console.log(Object.fromEntries(formData))
        try {
            const { data } = await api.post(path, formData, 
                { headers: await getHeaders(authentication, secured), withCredentials: true })

            return data
        } catch (error: any) {
            // console.log(error);
            // console.log('res: ', (error.response?.status))
            // console.log('err: ', error.config.url)
            if (error.config.url === 'signin/' && error?.response?.data['non_field_errors'][0] === 'Incorrect Credentials'){
                return {'res': 'failed', 'message': 'Incorrect Credentials'}
            }

            if ((error.response?.status === 401) && (error.config.url === 'signout/')){
                console.log('Signout failed ...')
            }
            // console.log(err?.response);
            // console.log(err?.response?.data['non_field_errors'][0]);
            // window.alert(err?.response?.data['non_field_errors'][0])
        }
    }

    const $put = async (path: string, body: any) => {
        try {
            const { data } = await api.put(path, body, { headers: await getHeaders(true) })
            return data
        } catch (err: any) {
            console.log(err?.response);
            console.log(err.message)
        }
    }

    const $patch = async (path: string, body: any) => {
        try {
            const { data } = await api.patch(path, body, { headers: await getHeaders(true) })
            return data
        } catch (err: any) {
            console.log(err.message)
        }
    }

    const $delete = async (path: string, body= {}, buildForm=true,) => {
        var formData = body
        if (buildForm){formData = makeFormData(body)}
        try {
            const { data } = await api.delete(path, { headers: await getHeaders(true), data:formData })
            return data
        } catch (err: any) {
            console.log(err.message)
        }
    }

    const objToArray = (data: any): any[] => {
        const formatedData: any[] = [];
        Object.keys(data).map((item, i) => {
            const operation = Object.keys(data[item]).map((key, i) => ({
                id: key,
                date: item,
                ...data[item][key],
            }));
            formatedData.push(...operation);
        });
        return formatedData
    }


    return { getHeaders, setUserId, $post, $get, $put, $patch, $delete, objToArray }
}